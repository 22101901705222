import React, { memo, useCallback, useContext, useEffect, useMemo } from 'react';
import {
  useCycleApplicantsDetailsList,
  useCycleDegrees,
  useCycleRegions,
  useCycleStatuses,
  useCycleTableModuleInfos,
  useLazyCycleApplicantsDetailsList
} from './CycleHooks';
import {
  NamespaceContext,
  TableQueryingContext,
  TableRowIdsContext,
  CycleContext,
  TableRowContext
} from '../../core/ReactTable/TableContexts';
import { useDispatch, useSelector } from 'react-redux';
import { selectParamsLoaded } from '../../core/ReactTable/paramsSlice';
import { useCycleApplicantsDetailListTable } from './DetailViewHooks';
import { useAccount } from '../../util/Hooks';
import { Box, Center, Grid, Loader, Space, Table } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { CycleDetailViewActionsContainer } from './DetailViewApp';
import { DetailViewSelectedHandler } from './DetailViewSelectedHandler';
import { BetaCardWrapper } from './ApplicantCard/ApplicantCard';
import { CleanTable, CleanTableContextsProvider } from '../../core/ReactTable/CleanTable';
import {
  rowsLoaded,
  selectTableActiveRequestId,
  selectTableRowById,
  selectTableRowIdsByActiveRequestId
} from '../../core/ReactTable/tableRowsSlice';
import { selectAllOpenedKeys, selectCard, toggleCard } from './detailViewSlice';
import { selectIsRowSelected } from '../../core/ReactTable/selectedColumnsSlice';
import { useHover } from '@mantine/hooks';
import { useSpringGestures } from '../../core/useSpringGestures';
import FlagsModal from './FlagsModal'
import RezviewModal from './RezviewModal'

export function DetailViewTableApp () {
  const { cycleId } = useParams()
  const account = useAccount()
  const dispatch = useDispatch()
  const openedCards = useSelector(state => selectAllOpenedKeys(state))
  const statusesResult = useCycleStatuses(cycleId)
  const moduleInfosResult = useCycleTableModuleInfos(cycleId)

  const cycleData = useMemo(() => {
    const [modules] = moduleInfosResult
    const [statuses] = statusesResult

    const _modules = modules?.items ?? []

    return {
      id: cycleId,
      statuses: statuses?.items ?? [],
      modules: _modules
    }
  }, [statusesResult, moduleInfosResult, cycleId])

  const tableSpan = useMemo(() => openedCards.length > 0 ? 8 : 12, [openedCards])

  useSpringGestures()

  return (
    <CycleContext.Provider value={cycleId}>
      <Grid align='stretch'>
        <Grid.Col span={tableSpan}>
          <DetailViewTableActionsBar />
          <DetailViewSelectedHandler account={account} />
          <DetailViewTableLoader cycleId={cycleId} account={account} dispatch={dispatch} />
        </Grid.Col>
        {openedCards.length > 0 &&
          <Grid.Col span={4} pos='relative' style={{ zIndex: 1 }}>
            <Box pos='sticky' style={{ top: '8rem' }}>
              {openedCards.map(id => (
                <BetaCardWrapper
                  key={`applicant-card-${id}`}
                  applicantId={id}
                  cycleData={cycleData}
                  account={account}
                />
              ))
              }
            </Box>
          </Grid.Col>
        }
      </Grid>
      <FlagsModal />
      <RezviewModal />
    </CycleContext.Provider>
  )
}

export const DetailViewTableActionsBar = memo(function DetailViewTableActionsBar () {
  return (
    <div className='detail-view'>
      <div style={{ float: 'right', height: 0, overflow: 'visible' }}>
        <CycleDetailViewActionsContainer containerName='react-app-container'/>
      </div>
    </div>
  )
})

const DetailViewTableLoader = memo(function DetailViewTableLoader ({ cycleId, account }) {
  const [moduleInfos, modulesRefreshing] = useCycleTableModuleInfos(cycleId)
  const [statuses, statusesRefreshing] = useCycleStatuses(cycleId)
  const [regions, regionsRefreshing] = useCycleRegions(cycleId)
  const [educationOptions, educationOptionsRefreshing] = useCycleDegrees(cycleId)

  const anyLoading = modulesRefreshing || !account.email || statusesRefreshing || regionsRefreshing || educationOptionsRefreshing

  const statusTiers = useMemo(() => {
    if (!statuses?.items) {
      return []
    }
    const tiersMap = new Map()
    for (const status of statuses.items) {
      if (status.tier) {
        tiersMap.set(status.tier.id, status.tier)
      } else {
        console.warn('Did not find tier where expected in status.', { status, statuses })
      }
    }
    return [...tiersMap.values()]
  }, [statuses])

  return (
    <>
      {!!anyLoading && (
        <>
          <Space h='xxl' />
          <Center>
            <Loader type='bars' />
          </Center>
        </>
      )}
      {!anyLoading && (
        <DetailViewTable
          cycleId={cycleId}
          account={account}
          moduleInfos={moduleInfos}
          statuses={statuses}
          statusTiers={statusTiers}
          regions={regions}
          educationOptions={educationOptions}
        />
      )}
    </>
  )
})

const DetailViewTable = memo(function DetailViewTable (
  {
    cycleId,
    account,
    moduleInfos,
    statuses,
    statusTiers,
    regions,
    educationOptions,
    namespace = 'cycle-detail-view'
  }
) {
  const [applicants] = useLazyCycleApplicantsDetailsList(namespace, cycleId)

  const dispatch = useDispatch()

  const handleRowClicked = useCallback((applicantId) => {
    console.debug('Detail view table row clicked.', applicantId)
    if (!applicantId) return true
    const intApplicantId = parseInt(applicantId)
    const applicant = (applicants?.items?.filter(a => a.id === intApplicantId) ?? [])[0] ?? null
    if (!applicant) {
      console.warn('Unable to find applicant matching clicked id.', { applicantId, intApplicantId, cycleId, applicant, applicants })
      return true
    }

    dispatch(toggleCard({ id: intApplicantId }))
  }, [applicants, cycleId, dispatch])

  const { columns, defaultFilters, defaultHiddenColumns, abstractOrderVariants, ...otherTableProps } = useCycleApplicantsDetailListTable(
    namespace, cycleId, account, moduleInfos, statuses, statusTiers, regions, educationOptions
  )

  return (
    <CleanTableContextsProvider
      columns={columns}
      namespace={namespace}
      defaultFilters={defaultFilters}
      defaultHiddenColumns={defaultHiddenColumns}
      abstractOrderVariants={abstractOrderVariants}
    >
      <CycleDetailTableDataProvider cycleId={cycleId}>
        <CleanTable
          {...otherTableProps}
          abstractOrderVariants={abstractOrderVariants} // TODO [long term/order variants] refactor into redux state or provider
          onRowClick={handleRowClicked}
          columns={columns}
        />
      </CycleDetailTableDataProvider>
    </CleanTableContextsProvider>
  )
})

const getStatusTierColor = (status) => {
  const NOT_RECOMMENDED = 3
  const WITHDREW = 4
  const HIRED = 2
  const id = status.tier.id

  switch (id) {
    case HIRED:
      return 'green.1'
    case NOT_RECOMMENDED:
    case WITHDREW:
      return 'gray.3'
    default:
  }
}

const defaultRowStyles = {
  transition: 'background 200ms',
  cursor: 'pointer',
  filter: 'brightness(1)'
}

const CycleDetailTableRow = ({ children, rowId, ...props }) => {
  const namespace = useContext(NamespaceContext)
  const isSelected = useSelector(state => selectIsRowSelected(state, rowId))
  const applicantCard = useSelector(state => selectCard(state, rowId))
  const applicant = useSelector(state => selectTableRowById(state, `${namespace}-${rowId.toString()}`)) // something about this seems wrong
  const { ref, hovered } = useHover()

  const hoverStyles = useMemo(() => hovered
    ? {
        filter: 'brightness(0.9)'
      }
    : {
        transition: 'filter 100ms'
      }, [hovered])

  const bg = (!!applicantCard && 'yellow.0') || (isSelected && 'blue.1') || getStatusTierColor(applicant.status)

  const contextProps = useMemo(() => ({ bg: bg, style: { ...defaultRowStyles, ...hoverStyles } }), [bg, hoverStyles])

  return (
    <Table.Tr ref={ref} {...props} {...contextProps}>
      {children}
    </Table.Tr>
  )
}

function CycleDetailTableDataProvider ({ cycleId, children }) {
  const namespace = useContext(NamespaceContext)
  const dispatch = useDispatch()
  const loaded = useSelector(state => selectParamsLoaded(state, namespace))
  const activeRequestId = useSelector(state => selectTableActiveRequestId(state, namespace))
  const rowIds = useSelector(state => selectTableRowIdsByActiveRequestId(state, namespace))
  const [collection, queryRunning, , requestId] = useCycleApplicantsDetailsList(namespace, cycleId, !loaded)
  const querying = queryRunning || !loaded

  useEffect(() => { // Necessary as there's no good way to hook into loading from cache.
    console.debug('Cached table request sync effect running', { requestId, activeRequestId, collection })
    if (requestId && activeRequestId && collection && (requestId !== activeRequestId) && !queryRunning) {
      console.info('CycleDetailTableDataProvider request id changed', { requestId, activeRequestId })
      dispatch(rowsLoaded({ namespace: namespace, data: collection, requestId: requestId }))
    }
  }, [requestId, activeRequestId, collection, queryRunning, namespace, dispatch])

  console.debug('Cycle detail table data provider updated.', { rowIds, collection, querying, namespace, cycleId, requestId, activeRequestId })
  return (
    <TableRowIdsContext.Provider value={rowIds}>
      <TableRowContext.Provider value={CycleDetailTableRow}>
        <TableQueryingContext.Provider value={!!(querying || ((requestId !== activeRequestId) && requestId && activeRequestId))}>
          {children}
        </TableQueryingContext.Provider>
      </TableRowContext.Provider>
    </TableRowIdsContext.Provider>
  )
}
