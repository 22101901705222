import React from 'react'
import { Skeleton, Space, Stack } from '@mantine/core'

function RezviewSkeleton () {
  return (
    <>
      <Stack align="center" justify="flex-start">
        <Skeleton height={8} mt={6} width="20%" radius="xl"/>
        <Skeleton height={8} mt={6} width="25%" radius="xl"/>
        <Skeleton height={8} mt={6} width="25%" radius="xl"/>
        <Skeleton height={8} mt={6} width="20%" radius="xl"/>
      </Stack>
      <Space h="md"/>
      <Space h="md"/>
      <Space h="md"/>
      <Skeleton height={8} mt={6} width="70%" radius="xl"/>
      <Space h="md"/>
      <Skeleton height={8} mt={6} width="60%" radius="xl"/>
      <Space h="md"/>
      <Skeleton height={8} mt={6} width="40%" radius="xl"/>
      <Space h="md"/>
      <Skeleton height={8} mt={6} width="50%" radius="xl"/>
      <Space h="md"/>
      <Skeleton height={8} mt={6} width="70%" radius="xl"/>
      <Space h="md"/>
      <Space h="md"/>
      <Space h="md"/>
      <Skeleton height={8} mt={6} width="80%" radius="xl"/>
      <Space h="md"/>
      <Skeleton height={8} mt={6} width="60%" radius="xl"/>
      <Space h="md"/>
      <Skeleton height={8} mt={6} width="70%" radius="xl"/>
      <Space h="md"/>
      <Skeleton height={8} mt={6} width="60%" radius="xl"/>
      <Space h="md"/>
      <Skeleton height={8} mt={6} width="40%" radius="xl"/>
      <Space h="md"/>
      <Space h="md"/>
      <Space h="md"/>
      <Skeleton height={8} mt={6} width="70%" radius="xl"/>
      <Space h="md"/>
      <Skeleton height={8} mt={6} width="60%" radius="xl"/>
      <Space h="md"/>
      <Skeleton height={8} mt={6} width="80%" radius="xl"/>
      <Space h="md"/>
      <Skeleton height={8} mt={6} width="50%" radius="xl"/>
      <Space h="md"/>
      <Skeleton height={8} mt={6} width="40%" radius="xl"/>
      <Space h="md"/>
      <Space h="md"/>
      <Space h="md"/>
      <Skeleton height={8} mt={6} width="80%" radius="xl"/>
      <Space h="md"/>
      <Skeleton height={8} mt={6} width="60%" radius="xl"/>
      <Space h="md"/>
      <Skeleton height={8} mt={6} width="70%" radius="xl"/>
      <Space h="md"/>
      <Skeleton height={8} mt={6} width="60%" radius="xl"/>
      <Space h="md"/>
      <Skeleton height={8} mt={6} width="40%" radius="xl"/>
    </>
  )
}

export default React.memo(RezviewSkeleton)
