/* eslint react/prop-types: 0 */

import React, { useCallback, useMemo, useRef } from 'react'
import {
  Box,
  ScrollArea,
  ColorSwatch,
  Tooltip,
  ThemeIcon,
  Group,
  ActionIcon,
  Stack,
  Flex,
  Button,
  Space,
  Center,
  HoverCard,
  Text,
  Transition,
  Popover
} from '@mantine/core';
import * as DOMPurify from 'dompurify';
import { useDisclosure, useHover, useMergedRef } from '@mantine/hooks';
import { Tag, tagFromId } from '../../../js/generated/enums/Tag';
import { IconMail, IconX } from '@tabler/icons-react';
import * as styles from './DetailViewNotesCell.module.scss';

const MAX_POPUP_HEIGHT = '15rem'
const DROPDOWN_OFFSET = 5

export function DetailViewNotesCell ({ value, row, mah }) {
  const { scores, tag, id } = row.original
  const COURTESY_LETTER_MODULE_ID = 52
  const courtesyLetter = scores.filter(elem => elem.module_id === COURTESY_LETTER_MODULE_ID)[0]?.score ?? null
  const [focused, { open, close }] = useDisclosure(false)
  const hasComments = value.length > 0
  const viewport = useRef(null)
  const rootRef = useRef(null)
  const sizeRef = useRef(null)
  const { hovered, ref } = useHover()
  const popoverOffset = -(sizeRef.current?.clientHeight + DROPDOWN_OFFSET) ?? 0

  const showOpenNotesButton = useMemo(() => {
    const isScrolled = viewport.current?.scrollTop > 0

    return focused || hovered || isScrolled
  }, [focused, hovered])

  const mergedRef = useMergedRef(ref, rootRef)

  const handleOpen = useCallback((event) => {
    if (window.getSelection().toString().length > 0) return
    event.stopPropagation()
    open()
  }, [open])

  if (!hasComments && !tag && !courtesyLetter) {
    return (
      <Flex justify='center' align='center' ref={mergedRef} className={styles.root} h={mah} style={{ cursor: 'pointer' }}>
        <OpenNotesButton id={id} opened={showOpenNotesButton} h={mah} />
      </Flex>
    )
  }

  return (
    <Box mah={mah} className={styles.root} ref={mergedRef} h={mah} pos='relative'>
      <Popover opened={focused} position='bottom' offset={popoverOffset} width='target'>
        <Popover.Target ref={sizeRef}>
          <Box style={{ borderRadius: 4 }} ref={ref}>
            <ScrollArea.Autosize mah={mah} onClick={e => e.stopPropagation()} viewportRef={viewport}>
              <OpenNotesButton id={id} opened={showOpenNotesButton} h={mah} />
              <Space h='xs' />
              <Box onClick={handleOpen} className={styles.commentsContainer} p='xs' w='100%'>
                <NotesCellTopItems tag={tag} courtesyLetter={courtesyLetter} mb={hasComments && 'sm'} />
                <NotesCell applicantId={id} comments={value} />
              </Box>
            </ScrollArea.Autosize>
          </Box>
        </Popover.Target>
        <Popover.Dropdown px={0} onClick={e => e.stopPropagation()} mih={mah}>
          <Box>
            <Flex justify='space-between' align='center' gap='sm' px='xs' mb={hasComments && 'sm'}>
              <NotesCellTopItems tag={tag} courtesyLetter={courtesyLetter} />
              <ActionIcon onClick={close}><IconX /></ActionIcon>
            </Flex>
            <ScrollArea.Autosize mah={MAX_POPUP_HEIGHT} style={{ cursor: 'default', overflow: 'hidden', textWrap: 'wrap' }}>
              <Box px='xs'>
                <NotesCell applicantId={id} comments={value} px='xs' />
              </Box>
            </ScrollArea.Autosize>
          </Box>
        </Popover.Dropdown>
      </Popover>
    </Box>
  )
}

function OpenNotesButton ({ id, opened }) {
  const targetRef = useRef()
  const openCard = useCallback((e) => {
    e.stopPropagation()
    const parentRow = document.querySelector(`tbody tr[data-rowid="${id}"]`)
    if (parentRow) {
      parentRow.click()
    }
  }, [id])

  return (
    <Center>
      <Transition mounted={opened} transition='fade-down' duration={300}>
        {(transitionStyles => (
          <Box style={transitionStyles}>
            <HoverCard closeDelay={150} openDelay={300} shadow='md' defaultOpened={false} position='top' width='15rem'>
              <HoverCard.Target ref={targetRef}>
                <Button variant='outline' color='gray' style={styles} onClick={openCard}>Open Notes</Button>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Text>Notes are private and will not be seen by anyone except you and authorized account members.</Text>
              </HoverCard.Dropdown>
            </HoverCard>
          </Box>
        ))}
      </Transition>
    </Center>
  )
}

function NotesCell ({ applicantId, comments, ...props }) {
  return comments.length > 0 && (
    <Stack style={{ cursor: 'auto' }} gap='xs' {...props}>
    {comments.map((comment) => {
      return (
      <Box key={`${applicantId}-comment-${comment.id}`}>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(comment.content) }}></div>
      </Box>
      )
    })}
  </Stack>
  )
}

function NotesCellTopItems ({ tag, courtesyLetter, ...props }) {
  return (
    <Group justify='center' {...props}>
    {!!tag && <ColorSwatch size={18} color={getTagColor(tagFromId(tag))} />}
    {!!courtesyLetter && <Tooltip label={courtesyLetter}><ThemeIcon size='md' variant='light' color='orange'><IconMail size='1.75rem' /></ThemeIcon></Tooltip>}
    </Group>
  )
}

export function getTagColor (tag) {
  switch (tag) {
    case Tag.Black:
      return 'rgba(0, 18, 25, 1)'
    case Tag.Blue:
      return 'rgba(0, 95, 155, 1)'
    case Tag.Green:
      return 'rgba(148, 230, 189, 1)'
    case Tag.Yellow:
      return 'rgba(238, 155, 0, 1)'
    case Tag.Red:
      return 'rgba(174, 32, 18, 1)'
    default:
      return 'rgba(255, 255, 255, 0)'
  }
}
