/* eslint react/prop-types: 0 */
import React, { useState } from 'react'
import { ActionIcon, Anchor, Button, Grid, Group, Modal, Switch, Tooltip, UnstyledButton } from '@mantine/core'
import { IconEyeCheck, IconFileTypeXls, IconReplace, IconTrash } from '@tabler/icons-react'
import { useDisclosure } from '@mantine/hooks'
import BatteryAddForm from './BatteryAddForm'
import ErrorLoading from '../../core/ErrorLoading'
import Loading from '../../core/Loading'
import { useGetApBatteriesQuery } from '../../../redux/query/build/batteriesApi.slice'
import _ from 'lodash'
import WarningAlert from '../../core/Alert/WarningAlert'
import { showNotification } from '@mantine/notifications'
import BatteryReplaceForm from './BatteryReplaceForm'

/**
 * @param {array} linkedBatteries an array of linked batteries.
 * @param {array} triggers an array of triggers.
 * @param {function} toggleBattery function to execute when the user wants to toggle a battery state.
 * @param {function} reviewBattery function to execute when the user wants to review a battery.
 * @param {function} exportBattery function to execute when the user wants to export item level data for a battery.
 * @param {function} addBattery function to execute when the user wants to add a new battery.
 * @param {function} editBattery function to execute when the user wants to edit a battery.
 * @param {function} deleteBattery function to execute when the user wants to delete a battery.
 * @returns {React.ReactElement} The rendered battery management component.
 */
export default function BatteryManagement ({
  linkedBatteries,
  triggers,
  toggleBattery,
  reviewBattery,
  exportBattery,
  addBattery,
  editBattery,
  deleteBattery
}) {
  const { data: batteries, error, isLoading } = useGetApBatteriesQuery({ limit: 0, active: 1 })
  const [batteryToReplace, setBatteryToReplace] = useState(false)
  const [batteryToDelete, setBatteryToDelete] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [opened, handlers] = useDisclosure(false)

  if (error) {
    return <ErrorLoading />
  }

  if (isLoading) {
    return <Loading />
  }

  const remove = () => {
    deleteBattery(batteryToDelete.id)
      .then(() => {
        showNotification({
          message: 'Battery deleted successfully!',
          color: 'success',
          autoClose: 3000
        })
      })
      .catch(err => {
        if (err.data?.type === 'validation_error') {
          for (const message of err.data.errors) {
            showNotification({
              title: 'Validation error',
              message: message,
              color: 'red'
            })
          }
        } else {
          showNotification({
            title: 'Something went wrong',
            message: 'There was an error deleting this battery',
            color: 'red'
          })
        }
      })
      .finally(closeDeleteModal)
  }

  const toggle = battery => {
    toggleBattery(battery.id)
      .then(() => {
        showNotification({
          message: 'Battery updated successfully!',
          color: 'success',
          autoClose: 3000
        })
      })
      .catch(err => {
        if (err.data?.type === 'validation_error') {
          for (const message of err.data.errors) {
            showNotification({
              title: 'Validation error',
              message: message,
              color: 'red'
            })
          }
        } else {
          showNotification({
            title: 'Something went wrong',
            message: 'There was an error updating this battery',
            color: 'red',
            autoClose: 3000
          })
        }
      })
  }

  const download = linkedBattery => {
    setDownloading(true)
    exportBattery(linkedBattery.id).finally(() => setDownloading(false))
  }

  const closeDeleteModal = () => setBatteryToDelete(false)

  const closeReplaceModal = () => setBatteryToReplace(false)

  const batteriesThatCanBeAdded = _.filter(batteries.items, battery => !_.includes(_.map(linkedBatteries ?? [], 'cycle.id'), battery.id))

  return (
    <>
      <Modal opened={!!batteryToReplace} onClose={closeReplaceModal} title='Replace battery'>
        {!!batteryToReplace && <BatteryReplaceForm editBattery={values => editBattery(batteryToReplace.id, values).then(closeReplaceModal)} battery={batteryToReplace} batteries={batteriesThatCanBeAdded} triggers={triggers}/>}
      </Modal>
      <Modal opened={!!batteryToDelete} onClose={closeDeleteModal} title='Delete battery'>
        <Group spacing='xs'>
          {
            batteryToDelete &&
            _.size(batteryToDelete.battery_score_mappings) > 0 &&
            <WarningAlert>This battery has mapped scores. This mapping will be removed.</WarningAlert>
          }
          <Button color='red.6' onClick={remove}>Delete</Button>
          <Button color='gray.6' onClick={closeDeleteModal}>Cancel</Button>
        </Group>
      </Modal>
      <Modal opened={opened} onClose={() => handlers.close()} title='Add battery'>
        <BatteryAddForm addBattery={values => addBattery(values).then(() => handlers.close())} batteries={batteriesThatCanBeAdded}/>
      </Modal>
      <Grid mt={10} mb={10}>
        {linkedBatteries.map(linkedBattery => (
          <Grid.Col key={linkedBattery.cycle.id}>
            <Anchor href={`cycles/${linkedBattery.cycle.id}/results`} target='_blank'>{linkedBattery.cycle.name}</Anchor>
            <Group gap='xs'>
              <Tooltip label={linkedBattery.active ? 'Disallow testing' : 'Allow testing'}>
                <UnstyledButton>
                  <Switch color='teal.6' checked={linkedBattery.active} size='md' onLabel='ON' offLabel='OFF' onChange={() => toggle(linkedBattery)} disabled={typeof toggleBattery === 'undefined'}/>
                </UnstyledButton>
              </Tooltip>
              {
                reviewBattery &&
                <Tooltip label='Review'>
                  <ActionIcon color='blue.6' variant='filled' aria-label='Review' onClick={() => reviewBattery(linkedBattery.id)}>
                    <IconEyeCheck style={{ width: '70%', height: '70%' }} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
              }
              {
                exportBattery &&
                <Tooltip label='Export'>
                  <ActionIcon color='blue.6' variant='filled' aria-label='Export' loading={downloading} onClick={() => download(linkedBattery)}>
                    <IconFileTypeXls style={{ width: '70%', height: '70%' }} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
              }
              <Tooltip label='Replace'>
                <ActionIcon color='blue.6' variant='filled' aria-label='Replace' onClick={() => setBatteryToReplace(linkedBattery)} disabled={typeof editBattery === 'undefined'}>
                  <IconReplace style={{ width: '70%', height: '70%' }} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
              <Tooltip label='Delete'>
                <ActionIcon color='red.6' variant='filled' aria-label='Delete' onClick={() => setBatteryToDelete(linkedBattery)} disabled={typeof deleteBattery === 'undefined'}>
                  <IconTrash style={{ width: '70%', height: '70%' }} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
            </Group>
          </Grid.Col>
        ))}
        <Grid.Col>
          <Group spacing='xs'>
            <Button color='teal.6' onClick={() => handlers.open()} disabled={typeof addBattery === 'undefined'}>Add battery</Button>
            <Button color='gray.6' onClick={() => window.history.back()}>Cancel</Button>
          </Group>
        </Grid.Col>
      </Grid>
    </>
  )
}
