import React, { useCallback, useContext, useMemo, useRef, useState, useEffect, memo, startTransition } from 'react'
import { ActionIcon, Badge, Box, Flex, Grid, Group, HoverCard, Indicator, LoadingOverlay, Stack, TagsInput, ThemeIcon, Tooltip } from '@mantine/core'
import * as DOMPurify from 'dompurify'
import { IconBan, IconCheck, IconChevronsLeft, IconEdit, IconPinFilled, IconPinnedOff, IconTag, IconTrash, IconX } from '@tabler/icons-react'
import { useDisclosure } from '@mantine/hooks'
import { useGetLoggedInAccountQuery } from '../../../../redux/query/account/accountsApi.slice'
import { isGranted } from '../../../../util/account/account.util'
import { useDeleteCommentMutation, useRemoveTagMutation, useUpdateCommentMutation, useUpdateCommentTagsMutation } from '../../../../redux/query/hire/commentsApi.slice'
import * as styles from './DiscussionComment.module.scss'
import { StatusContext } from './DiscussionContexts'
import { CommentEditor } from './NewDiscussion'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)

const MAX_TAGS_ALLOWED = 5

const nearScreenBuffer = 64
const defaultUseNearScreenOptions = { root: null, rootMargin: `${nearScreenBuffer}px ${nearScreenBuffer}px ${nearScreenBuffer}px ${nearScreenBuffer}px`, threshold: 0 }

function useNearScreen (onVisible, onVisibleId = null, onVisibleNamespace = null, options = defaultUseNearScreenOptions) { // Implementation mostly copied from table virtual row. Some variables are no longer useful, and the near screen buffer / box mih below might need to be tweaked.
  const containerRef = useRef(null)
  useEffect(() => {
    const callbackFunction = (entries) => {
      console.debug('Discussion Use near screen callback', { onVisibleId, onVisibleNamespace, options, entries })
      const [entry] = entries
      if (entry.isIntersecting) {
        console.debug('Discussion Starting on visible transition', { onVisibleId, onVisibleNamespace, entry, entries })
        startTransition(() => {
          onVisible(true)
        })
      }
    }
    const observer = new IntersectionObserver(callbackFunction, options)
    const container = containerRef.current
    console.debug('Discussion Use new screen effect updating', { options, container })
    if (container) {
      observer.observe(container)

      return () => {
        console.debug('Discussion Use new screen effect unmounting', { options, container })
        observer.unobserve(container)
      }
    }
  }, [containerRef, options, onVisible, onVisibleId, onVisibleNamespace])

  return containerRef
}

export function Comment ({ comment, isSubComment, tagList }) {
  const { id, content, account, created_at: createdAt, history, tags, applicant: { id: applicantId } } = comment
  const [editing, setEditing] = useState(false)
  const [updateComment, { isLoading: processingUpdateComment }] = useUpdateCommentMutation()
  const isFetchingComments = useContext(StatusContext)
  const [currentContent, setCurrentContent] = useState(content)
  const [nearScreen, setNearScreen] = useState(false)
  const screenRef = useNearScreen(setNearScreen, id, 'comment')

  const showOverlay = processingUpdateComment || isFetchingComments

  const update = useCallback((data) => {
    updateComment({ id, applicantId, ...data })
      .unwrap()
      .then(() => {
        setEditing(false)
        const event = new CustomEvent('discussion:comment-updated') // need this for updating legacy view tables
        window.dispatchEvent(event)
      })
  }, [updateComment, id, applicantId])

  const updateContent = useCallback(() => {
    update({ content: currentContent })
  }, [currentContent, update])

  const [createdAtDate, labelDate] = getFormattedDate(createdAt, isSubComment)

  const sanitizedContent = useMemo(() => {
    return DOMPurify.sanitize(content)
  }, [content])

  if (!nearScreen) {
    return (
      <Box ref={screenRef} mih='4rem' pos='relative'>
        <LoadingOverlay loaderProps={{ type: 'none' }} visible={showOverlay} />
      </Box>
    )
  }
  return (
    <Box className={styles.comment} pos='relative'>
    <LoadingOverlay loaderProps={{ type: 'none' }} visible={showOverlay} />
    <Grid columns={14} bg='white' p='xs'>
      <Grid.Col span={1} component={Flex} justify='center'>
        <Stack align='center' justify='flex-start' gap='xs'>
          {!isSubComment && <AuthorIcon author={account} />}
        </Stack>
      </Grid.Col>
      <Grid.Col span={10}>
        <Flex direction='column' justify='flex-start'>
          <Flex align='center' gap='xs'>
            {!isSubComment && <Box fw='bold' c='dark'>
              {account
                ? `${account.first_name} ${account.last_name}`
                : 'HireScore'
              }
            </Box>
            }
            <Tooltip label={labelDate}>
              <Box fz='xs' fs='italic' className={isSubComment && styles.hiddenUntilHover}>
                {createdAtDate}
              </Box>
            </Tooltip>
          </Flex>
          {editing
            ? <CommentEditor content={currentContent} onUpdate={setCurrentContent} onSubmit={updateContent} disabled={showOverlay} />
            : <Box dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
          }
          <Box>
            <CommentTags commentId={id} tags={tags} tagList={tagList} />
          </Box>
        </Flex>
      </Grid.Col>
      <Grid.Col span={3}>
        <Stack justify='flex-end'>
          <CommentActions comment={comment} onEditClick={() => setEditing(!editing)} editing={editing} onUpdateComment={update} />
          {history.length > 0 && (
            <EditedCommentBadge history={history} />
          )
          }
        </Stack>
      </Grid.Col>
    </Grid>
    </Box>
  )
}

const CommentActions = memo(function CommentActions ({ comment, onEditClick: handleEditClick, editing, onUpdateComment: handleUpdateComment }) {
  const { data: account } = useGetLoggedInAccountQuery()
  const { id, pinned, applicant: { id: applicantId } } = comment
  const [removeComment] = useDeleteCommentMutation()
  const [confirmRemoveComment, { open, close }] = useDisclosure(false)

  const isAdmin = isGranted(account, 'ROLE_ADMIN')
  const isUserComment = account.email === comment.account?.email

  const togglePinned = useCallback(() => {
    console.log(pinned)
    handleUpdateComment({ id: id, pinned: !pinned ? 1 : 0 })
  }, [handleUpdateComment, id, pinned])

  const remove = useCallback(() => {
    removeComment({ id, applicantId })
    const event = new CustomEvent('discussion:comment-deleted') // need this for updating legacy view tables
    window.dispatchEvent(event)
  }, [removeComment, applicantId, id])

  return (
    <Group justify='flex-end' align='flex-start' gap='xs' wrap='nowrap'>
      <CommentAction
        onClick={handleEditClick}
        label='Edit comment'
        disabled={!isUserComment}
        showOnHover={!editing}
        >
        {editing ? <IconX /> : <IconEdit />}
      </CommentAction>
      <CommentAction
        onClick={togglePinned}
        label={pinned ? 'Unpin comment' : 'Pin comment'}
        showOnHover={!pinned}
        >
        {pinned ? <IconPinFilled /> : <IconPinnedOff />}
      </CommentAction>
      {confirmRemoveComment
        ? <Tooltip label='Are you sure?'>
            <Flex>
              <ActionIcon variant='light' color='red' onClick={remove}><IconCheck /></ActionIcon>
              <ActionIcon variant='light' color='gray' onClick={close}><IconBan /></ActionIcon>
            </Flex>
          </Tooltip>
        : <CommentAction
            onClick={open}
            label='Delete comment'
            disabled={!(isAdmin || isUserComment)}
            showOnHover
            color='red'
            variant='discreet'
            >
            <IconTrash />
          </CommentAction>
      }
    </Group>
  )
})

function CommentAction ({ onClick: handleClick, label, loading, disabled, showOnHover = false, children, ...props }) {
  return disabled
    ? <ActionIcon disabled className={showOnHover && styles.hiddenUntilHover}>{children}</ActionIcon>
    : <Tooltip label={label}>
        <ActionIcon onClick={handleClick} className={showOnHover && styles.hiddenUntilHover} {...props}>
          {children}
        </ActionIcon>
      </Tooltip>
}

const CommentTags = memo(function CommentTags ({ commentId, tags: _commentTags, tagList: applicantTags }) {
  const [updateCommentTags] = useUpdateCommentTagsMutation()
  const [removeTag] = useRemoveTagMutation()
  const [commentTags, setCommentTags] = useState(_commentTags)
  const [searchValue, setSearchValue] = useState('')
  const limit = searchValue.length === 0 ? 0 : 20
  const ref = useRef()

  const [opened, { open, close }] = useDisclosure(commentTags.length < MAX_TAGS_ALLOWED)

  const handleTagsChange = useCallback((tags) => {
    const newTags = tags.filter(tag => !commentTags.includes(tag))
    const removedTags = commentTags.filter(tag => !tags.includes(tag))

    setCommentTags(tags)

    newTags.forEach(tag => updateCommentTags({ id: commentId, tag: tag }))
    removedTags.forEach(tag => removeTag({ id: commentId, tag: tag }))

    if (!opened) open()
  }, [commentTags, updateCommentTags, removeTag, commentId, open, opened])

  const hasMaxTags = commentTags.length >= MAX_TAGS_ALLOWED
  const hasNoTags = commentTags.length === 0

  return opened
    ? (
      <TagsInput
        ref={ref}
        leftSection={<Tooltip label='Add tag'><ActionIcon onClick={() => ref.current.focus()} className={styles.hiddenUntilHover}><IconTag /></ActionIcon></Tooltip>}
        rightSection={hasMaxTags && <Tooltip label='Hide tags'><ActionIcon onClick={close}><IconChevronsLeft /></ActionIcon></Tooltip>}
        variant='unstyled'
        value={commentTags}
        data={applicantTags}
        onChange={handleTagsChange}
        searchValue={searchValue}
        onSearchChange={setSearchValue}
        acceptValueOnBlur={false}
        className={(hasNoTags && searchValue.length === 0) && styles.hiddenUntilHover}
        pb={0}
        limit={limit}
        />
      )
    : (
      <Box className={hasMaxTags && styles.hiddenUntilHover}>
        <Indicator
          inline
          offset={3}
          size={16}
          disabled={hasNoTags}
          label={commentTags.length}
          position='bottom-end'
          variant='light'
          color='blue'
          >
          <Tooltip label='View tags'>
            <ActionIcon onClick={open}>
                <IconTag />
            </ActionIcon>
          </Tooltip>
        </Indicator>
      </Box>
      )
})

function EditedCommentBadge ({ history }) {
  const { edited_at: editedAt, account } = history[history.length - 1]
  const lastEdit = dayjs(editedAt).format('MMMM D, YYYY h:mma')

  return (
    <Flex justify='flex-end'>
    <HoverCard label={`Last edit: ${lastEdit}`}>
      <HoverCard.Target>
        <Badge size='sm' variant='outline' color='gray' >{history.length > 1 ? `edits: ${history.length}` : 'edited'}</Badge>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Box>
          Last edit: {lastEdit}
        </Box>
        <Box>
          By {account?.first_name} {account?.last_name} ({account?.email})
        </Box>
      </HoverCard.Dropdown>
    </HoverCard>
    </Flex>
  )
}

function AuthorIcon ({ author }) {
  if (!author) {
    return (
    <ThemeIcon color='gray' radius='xl' size='lg' fw='bold'>
      HS
    </ThemeIcon>
    )
  }
  const { first_name: first, last_name: last, email } = author
  return (
    <Tooltip label={email}>
      <ThemeIcon radius='xl' size='lg' fw='bold'>
        {first[0]}{last[0]}
      </ThemeIcon>
    </Tooltip>
  )
}

function getFormattedDate (date, isSubComment) {
  const formattedDate = dayjs(date)

  return isSubComment
    ? [
        `at ${formattedDate.format('h:mm A')}`,
        formattedDate.format('MMMM D, YYYY')
      ]
    : [
        formattedDate.fromNow(),
        formattedDate.format('MMMM D YYYY, h:mma')
      ]
}
