/**
 * @typedef {object} Node
 * @property {int} id
 * @property {?int} parentId
 * @property {?SkillBuilderFilterAssessmentCategory} assessmentCategory Null for root/new nodes.
 * @property {?SkillBuilderFilterType} type Null for root/new nodes.
 * @property {?int} value Null for root/new nodes.
 * @property {Map.<int: int>[]} nodes Node Ids
 */

/**
 * @typedef {object} ExportState
 * @property {Map.<int: Node>} nodes <intId: Node>
 * @property {int} nextFakeId Always negative.
 * @property {[]} jobs
 * @property {[]} locations
 * @property {[]} facilitators
 * @property {Map.<SkillBuilderFilterAssessmentCategory: Node>[]} legacyNodes
 * @property {bool} legacyModeEnabled
 * @property {bool} legacyModeOperatorAnd
 * @property {Map.<int: Node>[]} exportHistory
 * @property {int} activeExportHistoryIndex
 * @property {int} maxExportHistoryLength
 */

import { SkillBuilderFilterType } from '../../js/generated/enums/SkillBuilderFilterType';
import { SkillBuilderFilterAssessmentCategory } from '../../js/generated/enums/SkillBuilderFilterAssessmentCategory';

/**
 * @param {?string} storeId
 * @returns {ExportState}
 */
function createInitialExportState (storeId = null) {
  const nodes = new Map()
  nodes.set(0, { id: 0, parentId: null, assessmentCategory: null, type: null, value: null, nodes: new Map() })
  return {
    nodes: nodes,
    nextFakeId: -1,
    jobs: [],
    locations: (storeId !== null) ? [storeId] : [],
    facilitators: [],
    legacyNodes: new Map(),
    legacyModeEnabled: true,
    legacyModeOperatorAnd: false,
    exportHistory: [nodes],
    activeExportHistoryIndex: 0,
    maxExportHistoryLength: 50,
    compactMode: false
  }
}

const assessmentCategoryOptions = Object.freeze(Object.values(SkillBuilderFilterAssessmentCategory))

const filterTypeOptions = Object.freeze(Object.keys(SkillBuilderFilterType).map(element => {
  return Object.freeze({ label: element.split(/(?=[A-Z])/).join(' '), value: SkillBuilderFilterType[element] })
}))

const CustomExportUpdate = Object.freeze({
  SetJobs: 'set-jobs',
  SetLocations: 'set-locations',
  SetFacilitators: 'set-facilitators',
  AddNode: 'add-node',
  RemoveNode: 'remove-node',
  ClearNodes: 'clear-nodes',
  SetNodeType: 'set-node-type',
  SetNodeCategory: 'set-node-assessmentCategory',
  SetNodeValue: 'set-node-value',
  SetLegacyNode: 'set-legacy-node',
  SetLegacyMode: 'set-legacy-mode',
  SetLegacyModeOperator: 'set-legacy-mode-operator',
  SetCompactMode: 'set-compact-mode',
  UndoExportUpdate: 'undo-export-update',
  RedoExportUpdate: 'redo-export-update'
})

const LegacyNodeField = Object.freeze({
  ActiveNodes: 'activeNodes',
  AssessmentCategory: 'assessmentCategory',
  Type: 'type',
  Value: 'value'
})

/**
 * @param {*&ExportState} state
 * @param {object} action
 * @param {CustomExportUpdate} action.type
 * @param {int|undefined} action.nodeId?
 * @param {int|undefined} action.parentNodeId?
 * @returns {*&ExportState}
 */
function customExportReducer (state, action) {
  // TODO handle/allow changing state max history length via config mid-edit? store in cookie?
  switch (action.type) {
    case CustomExportUpdate.SetJobs:
    case CustomExportUpdate.SetLocations:
    case CustomExportUpdate.SetLegacyNode:
    case CustomExportUpdate.SetLegacyMode:
    case CustomExportUpdate.SetLegacyModeOperator:
    case CustomExportUpdate.SetCompactMode:
    case CustomExportUpdate.SetFacilitators: {
      return baseCustomExportReducer(state, action)
    }
    case CustomExportUpdate.UndoExportUpdate: {
      if (state.activeExportHistoryIndex <= 0) {
        console.warn(
          'Cannot undo action - no remaining histories to restore.',
          state.exportHistory,
          state.activeExportHistoryIndex
        )
        return state
      }
      const newState = { ...state }
      newState.activeExportHistoryIndex -= 1
      newState.nodes = new Map(newState.exportHistory[newState.activeExportHistoryIndex])
      console.debug(
        'Updated state due to undo. New nodes | new index | history | old nodes:',
        newState.nodes,
        newState.activeExportHistoryIndex,
        newState.exportHistory,
        state.nodes
      )
      return newState
    }
    case CustomExportUpdate.RedoExportUpdate: {
      if (state.activeExportHistoryIndex >= (state.exportHistory.length - 1)) {
        console.warn(
          'Cannot redo action - no remaining histories to restore.',
          state.exportHistory,
          state.activeExportHistoryIndex
        )
        return state
      }
      const newState = { ...state }
      newState.activeExportHistoryIndex += 1
      newState.nodes = new Map(newState.exportHistory[newState.activeExportHistoryIndex])
      console.debug(
        'Updated state nodes due to redo. New nodes | new index | history | old nodes:',
        newState.nodes,
        newState.activeExportHistoryIndex,
        newState.exportHistory,
        state.nodes
      )
      return newState
    }
    default: {
      const newState = baseCustomExportReducer(state, action)
      if (!Object.is(state.nodes, newState.nodes)) {
        console.debug(
          'State nodes changed from action type - adding to state history.',
          action.type
        )
        if (newState.activeExportHistoryIndex === (newState.exportHistory.length - 1)) {
          newState.exportHistory = [...newState.exportHistory, new Map(newState.nodes)]
          if (newState.exportHistory.length <= newState.maxExportHistoryLength) {
            newState.activeExportHistoryIndex += 1
          } else {
            newState.exportHistory.shift();
          }
          console.debug(
            'Appended new nodes state to history. New history | new index:',
            newState.exportHistory,
            newState.activeExportHistoryIndex
          )
        } else {
          newState.exportHistory = [
            ...newState.exportHistory.slice(0, newState.activeExportHistoryIndex + 1),
            new Map(newState.nodes)
          ]
          newState.activeExportHistoryIndex += 1
          console.debug(
            'Sliced state history update due to previous undo/redo. New history | new index | old history | old index:',
            newState.exportHistory,
            newState.activeExportHistoryIndex,
            state.exportHistory,
            state.activeExportHistoryIndex
          )
        }
      } else {
        console.debug(
          'State nodes did not change from base custom export reducer for action type - not adding history.',
          action.type
        )
      }
      return newState
    }
  }
}

/**
 * @param {*&ExportState} state
 * @param {object} action
 * @param {CustomExportUpdate} action.type
 * @param {int|undefined} action.nodeId?
 * @param {int|undefined} action.parentNodeId?
 * @param {boolean|undefined} action.andChildren?
 * @param {string|number|null|undefined} action.value?
 * @param {LegacyNodeField|undefined} action.field? LegacyNodes
 * @param {SkillBuilderFilterAssessmentCategory|undefined} action.category? LegacyNodes
 * @returns {*&ExportState}
 */
function baseCustomExportReducer (state, action) {
  switch (action.type) {
    case CustomExportUpdate.SetJobs:
    case CustomExportUpdate.SetLocations:
    case CustomExportUpdate.SetFacilitators: { // TODO reset other filters on change or verify no conflict?
      const stateAttribute = String(action.type).split('set-')[1]
      const newAttributes = {}
      newAttributes[stateAttribute] = action.value
      return { ...state, ...newAttributes }
    }
    case CustomExportUpdate.SetNodeCategory:
    case CustomExportUpdate.SetNodeType:
    case CustomExportUpdate.SetNodeValue: {
      const nodeId = action.nodeId
      const targetNode = state.nodes.get(nodeId)
      if (!targetNode) {
        console.error(
          'Could not find target node in state for node filter attributes update - skipping.',
          nodeId,
          action,
          state.nodes
        )
        return state
      }
      const newState = { ...state, nodes: new Map(state.nodes) }
      const nodeAttribute = String(action.type).split('set-node-')[1]
      const newAttributes = {}
      newAttributes[nodeAttribute] = action.value
      newState.nodes.set(nodeId, { ...targetNode, ...newAttributes })
      return newState
    }
    case CustomExportUpdate.AddNode: {
      const parentNodeId = action.parentNodeId
      const parentNode = state.nodes.get(parentNodeId)
      if (!parentNode) {
        console.error(
          'Could not find parent node in state for add node update - skipping.',
          parentNodeId,
          action,
          state.nodes
        )
        return state
      }
      const newNode = { id: state.nextFakeId, parentId: parentNodeId, assessmentCategory: SkillBuilderFilterAssessmentCategory.Overall, type: SkillBuilderFilterType.GreaterThanOrEqual, value: null, nodes: new Map() }
      const newState = { ...state, nodes: new Map(state.nodes), nextFakeId: state.nextFakeId - 1 }
      newState.nodes.set(newNode.id, newNode)
      newState.nodes.set(parentNodeId, {
        ...parentNode,
        nodes: new Map([
          ...parentNode.nodes,
          [newNode.id, newNode.id]
        ])
      })
      return newState
    }
    case CustomExportUpdate.ClearNodes: {
      const nodeId = 0
      const targetNode = state.nodes.get(nodeId)
      if (!targetNode) {
        console.error(
          'Could not find root node in state for clear nodes update - skipping.',
          nodeId,
          action,
          state.nodes
        )
        return state
      }
      if (!targetNode.nodes.size) {
        console.warn(
          'Root node had no children at time of clear nodes update - skipping.',
          nodeId,
          action,
          state.nodes
        )
        return state
      }
      const newState = { ...state, nodes: new Map() }
      newState.nodes.set(nodeId, { ...targetNode, nodes: new Map() })
      return newState
    }
    case CustomExportUpdate.RemoveNode: {
      const nodeId = action.nodeId
      const targetNode = state.nodes.get(nodeId)
      if (!targetNode) {
        console.error(
          'Could not find target node in state for remove node update - skipping.',
          nodeId,
          action,
          state.nodes
        )
        return state
      }
      const parentNodeId = targetNode.parentId
      const parentNode = state.nodes.get(parentNodeId)
      if (!parentNode) {
        console.error(
          'Could not find parent node in state for remove node update - skipping.',
          parentNodeId,
          targetNode,
          action,
          state.nodes
        )
        return state
      }
      if (!parentNode.nodes.has(nodeId)) {
        console.error(
          'Could not find child node id in parent node\'s children for remove node update - skipping.',
          parentNode,
          targetNode,
          action,
          state.nodes
        )
        return state
      }
      const andChildren = action.andChildren
      const newState = { ...state, nodes: new Map(state.nodes) }

      newState.nodes.delete(nodeId)
      const newParent = { ...parentNode, nodes: new Map(parentNode.nodes) }
      if (andChildren) {
        newParent.nodes.delete(nodeId)

        const getChildrenOfNode = (subNodeId) => {
          const allSubNodes = []
          for (const subNodeChildId of state.nodes.get(subNodeId).nodes.values()) {
            allSubNodes.push(subNodeChildId, ...getChildrenOfNode(subNodeChildId))
          }
          return allSubNodes
        }

        const descendantNodeIds = getChildrenOfNode(nodeId)
        console.debug('Descendant Node Ids of target node to be deleted.', nodeId, descendantNodeIds)
        for (const descendantNodeId of descendantNodeIds) {
          newState.nodes.delete(descendantNodeId)
        }
      } else {
        const preSiblingNodes = []
        const postSiblingNodes = []
        let matchedTarget = false
        for (const siblingNodeId of parentNode.nodes.values()) {
          if (siblingNodeId === nodeId) {
            matchedTarget = true
          } else {
            if (matchedTarget) {
              postSiblingNodes.push([siblingNodeId, siblingNodeId])
            } else {
              preSiblingNodes.push([siblingNodeId, siblingNodeId])
            }
          }
        }
        const raisedSiblingNodes = []
        for (const childId of targetNode.nodes.values()) {
          raisedSiblingNodes.push([childId, childId])
          newState.nodes.set(childId, { ...newState.nodes.get(childId), parentId: parentNodeId })
        }
        newParent.nodes = new Map([...preSiblingNodes, ...raisedSiblingNodes, ...postSiblingNodes])
        console.debug('New sibling node ids of deleted target node.', nodeId, newParent.nodes)
      }

      newState.nodes.set(parentNodeId, newParent)
      return newState
    }
    case CustomExportUpdate.SetLegacyNode: {
      const value = action.value
      const newState = { ...state, legacyNodes: new Map(state.legacyNodes) }
      switch (action.field) {
        case LegacyNodeField.ActiveNodes: {
          const newLegacyNodes = new Map()
          if (value) {
            for (const assessmentCategory of assessmentCategoryOptions) {
              for (const activeNodeCategory of value) {
                if (activeNodeCategory === assessmentCategory) {
                  if (state.legacyNodes.has(activeNodeCategory)) {
                    newLegacyNodes.set(activeNodeCategory, state.legacyNodes.get(activeNodeCategory))
                  } else {
                    newLegacyNodes.set(activeNodeCategory, {
                      id: 0,
                      parentId: 0,
                      assessmentCategory: activeNodeCategory,
                      type: SkillBuilderFilterType.GreaterThanOrEqual,
                      value: null,
                      nodes: new Map()
                    })
                  }
                }
              }
            }
          }
          newState.legacyNodes = newLegacyNodes
          return newState
        }
        case LegacyNodeField.Type: {
          const category = action.category
          newState.legacyNodes.set(category, { ...newState.legacyNodes.get(category), type: value })
          return newState
        }
        case LegacyNodeField.Value: {
          const category = action.category
          newState.legacyNodes.set(category, { ...newState.legacyNodes.get(category), value })
          return newState
        }
        default: {
          console.error('No known method of updating legacy node field.', action.field, action, state)
          return state
        }
      }
    }
    case CustomExportUpdate.SetLegacyMode: {
      const value = action.value
      if (value !== state.legacyModeEnabled) {
        return { ...state, legacyModeEnabled: value }
      }
      console.warn('Attempted to set legacy mode enabled to same value - skipping update.', action, state)
      return state
    }
    case CustomExportUpdate.SetCompactMode: {
      const value = action.value

      if (value !== state.compactMode) {
        return { ...state, compactMode: value }
      }

      return state
    }
    case CustomExportUpdate.SetLegacyModeOperator: {
      const value = action.value
      if (value !== state.legacyModeOperatorAnd) {
        return { ...state, legacyModeOperatorAnd: value }
      }
      console.warn('Attempted to set legacy mode operator to same value - skipping update.', action, state)
      return state
    }
    default: {
      console.error('Unknown export state update action type - skipping update.', action, state)
      return state
    }
  }
}

/**
 *
 * @param {Map.<SkillBuilderFilterAssessmentCategory, Node>} nodes
 * @param {boolean} modeOperatorAnd
 * @returns {Map<int, Node>}
 */
function formatLegacyNodes (nodes, modeOperatorAnd) {
  const formattedMap = new Map()
  const rootNode = { id: 0, parentId: null, assessmentCategory: null, type: null, value: null, nodes: new Map() }
  formattedMap.set(0, rootNode)
  let lastId = 0
  let lastNode = rootNode
  for (const node of nodes.values()) {
    lastId -= 1
    const newNode = { ...node, id: lastId, parentId: lastNode.id, nodes: new Map() }
    formattedMap.set(lastId, newNode)
    lastNode.nodes.set(lastId, lastId)
    if (modeOperatorAnd) {
      lastNode = newNode
    }
  }
  return formattedMap
}

function legacyFormatCustomExportState (state) {
  const filterFields = {
    'jobs-filter': state.jobs.length ? 'select' : 'all',
    'locations-filter': state.locations.length ? 'select' : 'all',
    'facilitators-filter': state.facilitators.length ? 'select' : 'all',
    compact: state.compactMode
  }
  const submitValues = { ...filterFields, scoresFilter: [] }
  if (state.jobs.length) {
    submitValues.jobIds = state.jobs
  }
  if (state.locations.length) {
    submitValues.locationIds = state.locations
  }
  if (state.facilitators.length) {
    submitValues.facilitatorIds = state.facilitators
  }

  const allNodeData = state.legacyModeEnabled ? formatLegacyNodes(state.legacyNodes, state.legacyModeOperatorAnd) : state.nodes

  const getSubNodeData = (node) => {
    const subNodeData = []
    for (const subNodeId of node.nodes.values()) {
      subNodeData.push(getSubNodeData(allNodeData.get(subNodeId)))
    }
    return { assessmentCategory: node.assessmentCategory, type: node.type, value: node.value, nodes: subNodeData }
  }

  submitValues.scoresFilter = getSubNodeData(allNodeData.get(0)).nodes
  return submitValues
}

export { createInitialExportState, CustomExportUpdate, LegacyNodeField, customExportReducer, legacyFormatCustomExportState, assessmentCategoryOptions, filterTypeOptions }
