import { ActionIcon, Anchor, Box, Button, Collapse, Divider, NavLink, ScrollArea } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import React, { memo, useCallback, useContext, useMemo, useReducer, useRef } from 'react'
import { IconCaretDownFilled, IconCaretUpFilled } from '@tabler/icons-react'
import { createInitialDropdownState } from './NewDropdownState'
import PropTypes from 'prop-types'
import * as styles from './DropdownListContent.module.scss'
import { DropdownHandlersContext } from './NewDropdown'

const ScrollViewportContext = React.createContext(null)

const DEFAULTS = {
  mah: 350
}

export const DropdownListContent = memo(function DropdownListContent ({ items, keyPrefix = null, itemProps, mah = DEFAULTS.mah, children, extrasAlwaysOpen = false }) {
  const defaultOpenedItemKeys = items.filter(item => item.metadata?.defaultOpened).map(item => getItemKey(item, keyPrefix))
  const viewport = useRef(null)

  const [state, dispatch] = useReducer(dropdownListReducer, { openedMenus: defaultOpenedItemKeys }, createInitialDropdownState)
  const toggleMenu = useCallback((key) => {
    dispatch({ type: 'toggle-menu', key: key })
  }, [dispatch])

  return (
    <>
    <ScrollArea.Autosize mah={mah} type='auto' offsetScrollbars viewportRef={viewport}>
      <ScrollViewportContext.Provider value={viewport}>
        {items.map((item) => {
          const key = getItemKey(item, keyPrefix)
          return (
            <DropdownItem key={key} item={item} isOpen={state.openedMenus.includes(key)} onOpenToggle={toggleMenu} {...itemProps} />
          )
        })}
      </ScrollViewportContext.Provider>
    </ScrollArea.Autosize>
    {!!children && (!extrasAlwaysOpen
      ? (
      <CollapsibleDrawer>
        {children}
      </CollapsibleDrawer>
        )
      : (
        <Box p='xs'>
          {children}
        </Box>
        ))
    }
    </>
  )
})

const ChildDropdownListContent = memo(function ChildDropdownListContent ({ items, keyPrefix, itemProps }) {
  const defaultOpenedItemKeys = items.filter(item => item.metadata?.defaultOpened).map(item => getItemKey(item, keyPrefix))

  const [state, dispatch] = useReducer(dropdownListReducer, { openedMenus: defaultOpenedItemKeys }, createInitialDropdownState)
  const toggleMenu = useCallback((key) => {
    dispatch({ type: 'toggle-menu', key: key })
  }, [dispatch])

  return items.map((item) => {
    const key = getItemKey(item, keyPrefix)
    return (
      <DropdownItem key={key} item={item} isOpen={state.openedMenus.includes(key)} onOpenToggle={toggleMenu} {...itemProps} />
    )
  })
})

const DropdownItem = memo(function DropdownItem ({ item, row = null, isOpen, onOpenToggle: handleOpenToggle }) {
  const { close } = useContext(DropdownHandlersContext)
  const key = getItemKey(item)
  const scrollRef = useRef(null)
  const viewport = useContext(ScrollViewportContext)
  const hasChildren = item.metadata?.items?.length > 0
  const isLink = item.href || item.component === Anchor

  const handleClick = useCallback(() => {
    item.onClick && item.onClick()
    if (item.metadata?.closeOnClick || (!isLink && !hasChildren)) {
      close()
    }
    handleOpenToggle(key)
  }, [handleOpenToggle, key, close, item, hasChildren, isLink])

  const rowProps = useMemo(() => {
    if (row) {
      if (item.metadata) {
        if (item.metadata.visible && item.metadata.visible(row) === false) {
          return null
        }
      }
      if (item.onClick) {
        return {
          onClick: () => item.onClick(row.id, row)
        }
      }
      if (typeof item.href === 'function') {
        return {
          href: item.href(row.id)
        }
      }
    }
    return {}
  }, [row, item])

  const itemProps = useMemo(() => {
    const variant = item.variant || 'discreet'
    const activeVariant = item.metadata?.activeVariant || `active-${variant}`
    const color = item.color || isLink ? 'blue' : 'gray'

    const newItem = { ...item }
    delete newItem.metadata

    return {
      variant: isOpen ? activeVariant : variant,
      color: isLink ? 'blue' : 'gray',
      c: color !== 'gray' ? undefined : 'dark', // the default gray has a bad contrast, so we need to change the font color to dark
      renderRoot: (props) => <Button component={isLink ? Anchor : undefined} variant={isOpen ? activeVariant : variant} {...props} />,
      ...newItem,
      onClick: handleClick
    }
  }, [item, isOpen, handleClick, isLink])

  const subItems = item.metadata?.items ?? []

  const scrollToItem = useCallback(() => {
    const SCROLL_DELAY = 150 // needed to wait for DOM to update
    if (scrollRef.current && viewport.current) {
      setTimeout(() => {
        const itemTop = scrollRef.current.offsetTop
        viewport.current.scrollTo({ top: itemTop, behavior: 'smooth' })
      }, SCROLL_DELAY)
    }
  }, [viewport])

  const handleItemChanged = useCallback((opened) => {
    if (opened) {
      scrollToItem()
    }
  }, [scrollToItem])

  return (
    <Box pos='relative' ref={scrollRef}>
      <NavLink
        label={item.label}
        childrenOffset={0}
        opened={isOpen}
        onChange={handleItemChanged}
        className={styles.listItem}
        active={isOpen}
        {...itemProps}
        {...rowProps}
        >
        {subItems.length > 0 &&
          <ChildDropdownListContent items={subItems} row={row} keyPrefix={key} viewport={viewport} />
        }
      </NavLink>
    </Box>
  )
})

const CollapsibleDrawer = memo(function CollapsibleDrawer ({ children }) {
  const [isCollapsed, { toggle }] = useDisclosure(true)
  const CollapsibleButton = useMemo(() => (
    <ActionIcon onClick={toggle} size='md'>
      {isCollapsed ? <IconCaretDownFilled /> : <IconCaretUpFilled />}
    </ActionIcon>
  ), [isCollapsed, toggle])

  return (
    <>
      <Divider label={CollapsibleButton} />
      <Collapse in={!isCollapsed}>
        <Box p='xs'>
          {children}
        </Box>
      </Collapse>
    </>
  )
})

function getItemKey (item, keyPrefix) {
  const key = item.id || item.label.replace(/\s/g, '_').replace(/[^a-zA-Z0-9-_]/g, '').toLowerCase()
  return keyPrefix ? `${keyPrefix}-${key}` : key
}

const dropdownListReducer = (state, action) => {
  switch (action.type) {
    case 'open-menu':
      return {
        ...state,
        openedMenus: state.openedMenus.concat(action.key)
      }
    case 'close-menu':
      return {
        ...state,
        openedMenus: state.openedMenus.filter(key => key !== action.key)
      }
    case 'toggle-menu':
      if (state.openedMenus.includes(action.key)) {
        return {
          ...state,
          openedMenus: state.openedMenus.filter(key => key !== action.key)
        }
      } else {
        return {
          ...state,
          openedMenus: state.openedMenus.concat(action.key)
        }
      }
    default:
      return state
  }
}

DropdownListContent.propTypes = {
  items: PropTypes.array,
  keyPrefix: PropTypes.string,
  itemProps: PropTypes.object,
  mah: PropTypes.number,
  children: PropTypes.node,
  extrasAlwaysOpen: PropTypes.bool
}

DropdownItem.propTypes = {
  item: PropTypes.object,
  row: PropTypes.object,
  isOpen: PropTypes.bool,
  onOpenToggle: PropTypes.func
}

CollapsibleDrawer.propTypes = {
  children: PropTypes.node
}
