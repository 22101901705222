import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import React, { useCallback, useState, useMemo, useRef, useEffect, memo } from 'react'
import { Box, Button, Flex, Group, LoadingOverlay, SimpleGrid, Stack, Switch } from '@mantine/core'
import { IconCaretDown, IconSend } from '@tabler/icons-react'
import StarterKit from '@tiptap/starter-kit'
import { BubbleMenu, useEditor } from '@tiptap/react'
import { RichTextEditor } from '@mantine/tiptap'
import { NewDropdown } from '../../../core/NewDropdown'
import { useGetCommentsQuery, usePostCommentMutation } from '../../../../redux/query/hire/commentsApi.slice'
import { Comment } from './DiscussionComment'
import { StatusContext } from './DiscussionContexts'
import { useWindowEvent } from '@mantine/hooks'
import _ from 'lodash'
import Loading from '../../../core/Loading'
dayjs.extend(duration)

const flippedProps = { style: { display: 'flex', flexDirection: 'column-reverse', overflowY: 'auto' } }

export function NewDiscussion ({ applicantId }) {
  const { data: comments, isFetching: processingFetchComments, isLoading } = useGetCommentsQuery(applicantId)
  const [postComment] = usePostCommentMutation()
  const [hideUnpinnedFromView, setHideUnpinnedFromView] = useState(false)
  const [startScrolling, setStartScrolling] = useState(false)
  const scrollableRef = useRef(null)

  const formattedComments = useMemo(() => {
    if (!comments) return []

    const newComments = [...comments]
    newComments.sort((a, b) => dayjs(b.created_at).diff(dayjs(a.created_at)))

    return newComments.filter(c => hideUnpinnedFromView ? c.pinned : true)
  }, [comments, hideUnpinnedFromView])

  useEffect(() => {
    if (startScrolling && !processingFetchComments) {
      scrollableRef.current.scrollTo({
        top: scrollableRef.current.scrollHeight,
        behavior: 'smooth'
      })
      setStartScrolling(false)
    }
  }, [startScrolling, processingFetchComments])

  const post = useCallback((content, pinned) => {
    if (content.length === 0) return
    setDisableSend(true)
    postComment({ applicantId: applicantId, content: content, pinned: pinned ? 1 : 0 })
      .unwrap()
      .then(() => {
        setStartScrolling(true)
        setDisableSend(false)
        const event = new CustomEvent('discussion:comment-posted') // is there a better way to do this?
        window.dispatchEvent(event)
      })
  }, [applicantId, postComment, setStartScrolling])

  const [disableSend, setDisableSend] = useState(false)

  const isFetchingComments = useMemo(() => processingFetchComments, [processingFetchComments])

  if (isLoading) {
    return <Loading />
  }

  const tags = _.uniq(_.flatMap(comments, 'tags'))

  return (
    <Box>
      <SimpleGrid columns={1}>
        <StatusContext.Provider value={isFetchingComments}>
          {formattedComments &&
            <Box mah='25rem' ref={scrollableRef} {...flippedProps}>
              {formattedComments.filter(c => hideUnpinnedFromView ? c.pinned : true).map((comment, index) => {
                const date = dayjs(comment.created_at)
                let isSubComment = false
                const isBotComment = !comment.account
                if (formattedComments[index + 1]?.account && !isBotComment) {
                  const prevComment = formattedComments[index + 1]
                  if (dayjs(prevComment.created_at).fromNow() === date.fromNow() ||
                  dayjs.duration(date.diff(prevComment.created_at)).asMinutes() < 5) isSubComment = true
                }
                return <Comment
                  key={comment.id}
                  comment={comment}
                  isBotComment={isBotComment}
                  isSubComment={isSubComment}
                  tagList={tags}
                  />
              })}
            </Box>
          }
          <ControlBar onSend={post} disableSend={disableSend} hideUnpinnedFromView={hideUnpinnedFromView } setHideUnpinnedFromView={setHideUnpinnedFromView} />
        </StatusContext.Provider>
      </SimpleGrid>
    </Box>
  )
}

export function CommentEditor ({ content, onSubmit: handleSubmit, disabled = false, onUpdate: updateContent }) {
  useWindowEvent('discussion:comment-posted', () => {
    updateContent('')
    editor.commands.clearContent()
  })

  const update = useCallback((content) => updateContent(content), [updateContent])

  const editor = useEditor({
    content: content,
    autofocus: 'end',
    editable: !disabled,
    onUpdate: ({ editor }) => {
      if (updateContent) {
        update(editor.getHTML())
      }
    },
    extensions: [
      StarterKit
    ],
    editorProps: {
      handleKeyDown: (editor, ev) => {
        if ((ev.key === 'Enter' && !ev.shiftKey)) {
          ev.preventDefault()
          submit()
          return true
        }
      }
    }
  }, [])

  const submit = useCallback(() => {
    if (editor.getText().length === 0 || disabled) return
    handleSubmit(editor.getHTML())
  }, [handleSubmit, editor, disabled])

  return (
    <Flex direction='column' justify='flex-end'>
      <RichTextEditor
        styles={{
          typographyStylesProvider: { paddingLeft: 0, marginBottom: 0 }
        }}
        editor={editor}
        >
        {editor && (
          <BubbleMenu editor={editor} tippyOptions={{ duration: 0 }}>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Link />
            </RichTextEditor.ControlsGroup>
          </BubbleMenu>
        )}
        <RichTextEditor.Content />
      </RichTextEditor>
      <Group justify='space-between' style={{ userSelect: 'none' }}>
        <Box fz='xs' c='dimmed'>Press Enter to submit</Box>
        <Box fz='xs' c='dimmed'>Press Shift + Enter to create a new line</Box>
      </Group>
    </Flex>
  )
}

const ControlBar = memo(function ControlBar ({ content = '', disableSend, onSend: post, withOptionsDropdown = true, hideUnpinnedFromView, setHideUnpinnedFromView }) {
  const [defaultPinned, setDefaultPinned] = useState(true)
  const [currentContent, setCurrentContent] = useState(content)

  const handleSubmit = useCallback(() => {
    post(currentContent, defaultPinned)
    setCurrentContent('')
  }, [currentContent, post, defaultPinned])

  const withOptionsStyles = withOptionsDropdown
    ? {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
      }
    : {}

  return (
    <Group align='center' wrap='nowrap' w='100%'>
      <Box style={{ flexGrow: 1 }} pos='relative'>
        <LoadingOverlay loaderProps={{ type: 'bars', mb: 'sm' }} visible={disableSend} />
        <CommentEditor content={content} onUpdate={setCurrentContent} onSubmit={handleSubmit} disabled={disableSend} clearOnSubmit />
      </Box>
      <Button.Group mb='md'>
        <Button onClick={handleSubmit} disabled={disableSend} style={withOptionsStyles}>
          <IconSend />
        </Button>
        {withOptionsDropdown &&
        <NewDropdown target={<Button px={0} variant='light' disabled={disableSend} style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}><IconCaretDown /></Button>}>
          <Stack p='xs'>
            <Switch label='Automatically pin comments' checked={defaultPinned} onChange={event => setDefaultPinned(event.currentTarget.checked)} />
            <Switch label='Hide unpinned comments from view' checked={hideUnpinnedFromView} onChange={event => setHideUnpinnedFromView(event.currentTarget.checked)} />
          </Stack>
        </NewDropdown>
        }
      </Button.Group>
    </Group>
  )
})
