import axios from 'axios'

const endpoint = '/api/v1/applicants'

export function getApplicants (params) {
  return axios({
    method: 'get',
    url: endpoint,
    params: params
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function getApplicant (id, cancelToken) {
  const config = {
    method: 'GET',
    url: `${endpoint}/${id}`
  }

  if (cancelToken) {
    config.cancelToken = cancelToken
  }

  return axios(config)
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function updateApplicant (id, json, method = 'patch') {
  return axios({
    method: method,
    url: `${endpoint}/${id}`,
    data: json
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function previewTemplate (id, json) {
  return axios({
    method: 'post',
    url: `${endpoint}/${id}/preview-template`,
    data: json
  })
    .then(function (response) {
      return response.data.data
    })
    .catch(function (error) {
      console.error(error)
      return false
    })
}

export function previewTextTemplate (id, json) {
  return axios({
    method: 'post',
    url: `${endpoint}/${id}/preview-text-template`,
    data: json
  })
    .then(function (response) {
      return response.data.data
    })
    .catch(function (error) {
      console.error(error)
      return false
    })
}

export function getFiles (id, params) {
  return axios({
    method: 'get',
    url: `${endpoint}/${id}/files`,
    params: params
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function createFile (id, data) {
  const formData = new FormData()
  formData.append('file', data.file)
  formData.append('description', data.description)

  data.tags.map(tag => formData.append('tags[]', tag))

  return axios({
    method: 'post',
    url: `${endpoint}/${id}/files`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function getRezview (id, signal = null) {
  const config = {
    method: 'get',
    url: `/applicants/${id}/rezview`
  }
  if (signal) {
    config.signal = signal
  }
  return axios(config)
    .then(response => response.data)
    .catch(err => {
      if (!axios.isCancel(err)) {
        console.error('Error fetching rezview.', err)
        throw err
      } else {
        console.info('Cancelled fetching rezview', err)
      }
    })
}

export function getRedactedRezview (id, signal) {
  return axios({
    method: 'get',
    url: `/applicants/${id}/rezview/redacted`,
    signal: signal
  }).then(response => response.data)
    .catch(err => {
      if (!axios.isCancel(err)) {
        console.error('Error fetching rezview.', err)
        throw err
      } else {
        console.info('Cancelled fetching rezview', err)
      }
    })
}
