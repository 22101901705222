import React, { memo, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { combineApplicantName, renderDateFromSource } from '../formatUtil';
import { Tag, tagFromId } from '../../../../../js/generated/enums/Tag';
import { getRedactedRezview, getRezview } from '../../../../../js/api/applicant_repository';
import * as DOMPurify from 'dompurify';
import { showNotification } from '@mantine/notifications';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Center,
  Group,
  Modal,
  Text,
  Title
} from '@mantine/core';
import { LockableColor } from './LockableIcon';
import RezviewSkeleton from '../../RezviewSkeleton'

export const ApplicantSummaryCard = memo(function ApplicantSummaryCard ({ applicant, isAdmin = false }) {
  const [opened, { open, close }] = useDisclosure(false)
  const [rezViewContent, setRezViewContent] = useState('')
  const applicantName = combineApplicantName(applicant)
  const accountName = combineApplicantName(applicant.account)
  const tag = tagFromId(applicant.tag)
  const tagColor = (tag === Tag.None) ? 'gray' : tag.toLowerCase()

  const fetchRezView = () => {
    if (!rezViewContent) {
      const rezView = isAdmin
        ? getRezview(applicant.id)
        : getRedactedRezview(applicant.id)

      rezView
        .then(response => setRezViewContent(response ? DOMPurify.sanitize(response) : ''))
        .catch((err) => {
          console.error('Error fetching rezView:', err.response)

          showNotification({
            title: 'Something went wrong',
            message: 'There was an error fetching the rezview',
            color: 'red',
            autoClose: 3000
          })
          close()
        })
    }
  }
  const onRezViewClick = () => {
    fetchRezView()
    open()
  }
  const activeColor = applicant.is_active ? LockableColor.Active : LockableColor.Locked
  return (
    <>
      <Card shadow="sm" padding="lg" radius="md" withBorder h='100%'>
        <Card.Section>
          <Box bg={activeColor} py='1rem' px='0.5rem'>
            <Title ta='center' order={3} c='white'>Applicant</Title>
          </Box>
        </Card.Section>
        <Card.Section mt={0} mb='1rem'>
          <Box style={{ position: 'relative' }}>
            <Badge color={tagColor} variant='light' style={{ position: 'absolute', top: '4px', right: '6px' }}>{applicant.status.name}</Badge>
            <Box mt='xxs' pt='2rem'>
              <Center>
                <Avatar
                  color={activeColor}
                  alt={applicantName || 'Applicant Name Missing'}
                  size='xl'
                >
                  {applicantName?.match(/\b\w/g)?.join('').toUpperCase() || '?'}
                </Avatar>
              </Center>
            </Box>
          </Box>
        </Card.Section>

        <Text fw={500} ta='center'>{applicantName}{(isAdmin && applicant.id) ? ` #${applicant.id}` : ''}</Text>

        <Text c={applicant.email_address ? 'teal' : 'red'} ta='center'>
          {applicant.email_address || 'Email Missing'}
        </Text>

        {!!isAdmin && (
          <Text c={applicant.account ? 'inherit' : 'dimmed'} ta='center' size={applicant.account ? 'md' : 'xxs'}>
            Account: {accountName || applicant.account?.email || 'None Assigned'}{applicant.account?.id ? ` #${applicant.account.id}` : ''}
          </Text>
        )}

        <Group justify="space-between" mt="auto" mb="xs">
          {!!applicant.created_at && <Text size="sm" c="dimmed">Created: {renderDateFromSource(applicant.created_at)}</Text>}
          {!!applicant.updated_at && <Text size="sm" c="dimmed">Updated: {renderDateFromSource(applicant.updated_at)}</Text>}
        </Group>

        <Button color="blue" fullWidth mt={0} radius="md" onClick={onRezViewClick}>
          View Details
        </Button>
      </Card>
      <Modal opened={opened} onClose={close} transitionProps={{ transition: 'slide-up' }} size='65rem'>
        { !!applicant.id && !!isAdmin && <ApplicantConversationButton applicantId={applicant.id} />}
        { !!rezViewContent && <div dangerouslySetInnerHTML={{ __html: rezViewContent }}></div>}
        { !rezViewContent && <RezviewSkeleton />}
      </Modal>
    </>
  )
})

const conversationButtonStyle = { float: 'right' }

function ApplicantConversationButton ({ applicantId }) {
  return (
    <Button
      variant='subtle'
      size='sm'
      component='a'
      href={`/applicants/${applicantId}/conversation`}
      target='_blank'
      style={conversationButtonStyle}
    >
      Conversation
    </Button>
  )
}
