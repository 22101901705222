import { useSelector } from 'react-redux'
import { selectModalValue } from './detailViewSlice'
import { useGetApplicantQuery, useGetFlagsContentQuery } from '../../../redux/query/hire/applicantsApi.slice'
import React, { useMemo } from 'react'
import * as DOMPurify from 'dompurify'
import DetailViewApplicantModal from './DetailViewApplicantModal'
import { Box, Flex, Grid, Skeleton, Stack } from '@mantine/core'

export default function FlagsModal () {
  const applicantId = useSelector(state => selectModalValue(state, 'flags'))
  const { data: flagsContent, isFetching } = useGetFlagsContentQuery(applicantId, { skip: !applicantId })
  const { data: applicant } = useGetApplicantQuery(applicantId, { skip: !applicantId })
  const sanitizedContent = useMemo(() => DOMPurify.sanitize(flagsContent), [flagsContent])

  return (
    <DetailViewApplicantModal modal='flags' transitionProps={{ transition: 'slide-left' }} applicantId={applicantId} title={applicant && `Flags for: ${applicant.first_name} ${applicant.last_name}`}>
      {isFetching && <FlagsSkeleton />}
      {!isFetching && sanitizedContent && (
        <div dangerouslySetInnerHTML={{ __html: sanitizedContent }}></div> // Todo [long term/flags] replace with react component
      )}
    </DetailViewApplicantModal>
  )
}

function FlagsSkeleton () {
  return (
    <Box>
      <Stack spacing='md' p='1rem'>
        <FlagSkeleton />
        <FlagSkeleton />
        <FlagSkeleton />
      </Stack>
    </Box>
  )
}

function FlagSkeleton () {
  const titleWidth = Math.floor(Math.random() * 60) + 20
  const descriptionWidth = Math.floor(Math.random() * 60) + 20

  return (
    <Grid columns={12} mb={20}>
      <Grid.Col span={1}>
        <Flex justify='center' align='center'>
          <Skeleton circle height={16} />
        </Flex>
      </Grid.Col>
      <Grid.Col span={11}>
        <Stack>
          <Skeleton height={10} width={`${titleWidth}%`} />
          <Skeleton height={10} width={`${descriptionWidth}%`} />
        </Stack>
      </Grid.Col>
    </Grid>
  )
}
