import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Group,
  ScrollArea,
  LoadingOverlay,
  Progress,
  Select,
  SimpleGrid,
  Space,
  Stack,
  Table,
  Text,
  Tooltip
} from '@mantine/core'
import {
  IconBoxMultiple,
  IconChevronDown,
  IconChevronUp,
  IconDotsVertical,
  IconEye,
  IconEyeOff,
  IconRefresh,
  IconSelector,
  IconSettings,
  IconSettingsAutomation,
  IconSettingsOff,
  IconArrowAutofitRight,
  IconArrowAutofitLeft
} from '@tabler/icons-react'
import React, {
  memo,
  startTransition,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import Dropdown from '../Dropdown'
import * as queryBarClasses from './QueryBar.module.scss'
import FilterControl from './Toolbar/FilterControl'
import Paginator from './Toolbar/Paginator'
import SearchBar from './Toolbar/SearchBar'
import FormFilters from './FormFilters';
import { createFormActions } from '@mantine/form';
import {
  paramsDictChanged,
  getFiltersFromDict,
  areSetsEqual,
  getNonNullParamsFromDict
} from './util';
import { useDispatch, useSelector } from 'react-redux';
import { useNamespacedStableSearchParams } from '../useStableSearchParams';
import {
  batchUpdateParams,
  segmentParams,
  selectParamsFilters,
  selectParamsIndexes, selectParamsLimit,
  selectParamsLoaded, selectParamsPage, selectParamsSearch,
  updateParamField,
  updateQuery
} from './paramsSlice';
import { useStableLocalStorage } from '../useStableLocalStorage';
import {
  NamespaceContext,
  TableCollapseContext,
  TableColumnsContext,
  TableFiltersContext,
  TableFiltersLoadedContext,
  TableQueryingContext,
  TableRowContext
} from './TableContexts';
import {
  columnsLoaded,
  makeId,
  resetColumns,
  selectActiveColumnIdsByNamespace,
  selectHiddenColumnIdsByNamespace,
  selectHiddenColumnMenuColumnIdsByNamespace,
  selectTableColumnAccessor,
  selectTableColumnActive,
  selectTableColumnById,
  selectTableColumnIdsByNamespace,
  selectTableColumnOrderBy,
  selectToggleColumnMenuColumnIdsByNamespace,
  selectToggleColumnMenuColumnsByNamespace,
  showAllColumnsAndResetExpansion,
  updateColumnCollapsed,
  updateColumnVisibility
} from './tableColumnsSlice';
import {
  selectRowActive,
  selectTableHasAnyRows,
  selectTableRowById,
  selectTableRowIdsByActiveRequestId,
  selectTableTotal,
  showRow
} from './tableRowsSlice';
import * as styles from './CleanTable.module.scss'
import { openModal } from '../../hire/cycle/detailViewSlice';
import { NewDropdown } from '../NewDropdown'
import { DropdownListContent } from '../DropdownListContent'

const PAGE_LIMIT_SIZES = ['20', '50', '100', '200', '500']
const DEFAULT_PAGE_LIMIT = '100'

/**
 * React table wrapper for all non-data table contexts.
 */
export function CleanTableContextsProvider ({ columns, namespace = '', defaultFilters = {}, defaultHiddenColumns = [], abstractOrderVariants = null, children }) {
  const [initialDefaultFilters] = useState(defaultFilters ?? {})
  const [initialHiddenColumnDefaults] = useState(new Set(defaultHiddenColumns ?? []))
  return (
    <NamespaceContext.Provider value={namespace}>
      <CleanTableColumnsContextProvider columns={columns} defaultHiddenColumns={initialHiddenColumnDefaults}>
        <CleanTableCollapseContextProvider columns={columns} >
          <CleanTableFiltersContextProvider defaultFilters={initialDefaultFilters} abstractOrderVariants={abstractOrderVariants}>
            <CleanTableControlContextProvider>
              {children}
            </CleanTableControlContextProvider>
          </CleanTableFiltersContextProvider>
        </CleanTableCollapseContextProvider>
      </CleanTableColumnsContextProvider>
    </NamespaceContext.Provider>
  )
}

/**
 * React table wrapper for save/clear filters and default filters functionality.
 */
export function CleanTableFiltersContextProvider ({ defaultFilters = {}, abstractOrderVariants = null, children }) {
  const namespace = useContext(NamespaceContext)
  const [loadedFilters, setLoadedFilters] = useState(false)
  const dispatch = useDispatch()
  const [searchParams] = useNamespacedStableSearchParams(namespace)
  const initialSearchParamsRef = useRef(searchParams)
  const savedFiltersConfig = {
    key: location.pathname + '-saved-table-filters-' + (namespace ?? ''),
    defaultValue: defaultFilters,
    serialize: (value) => JSON.stringify(value),
    deserialize: (str) => str === undefined ? defaultFilters : JSON.parse(str)
  }
  const [savedFilters, setSavedFilters, resetSavedFilters] = useStableLocalStorage(savedFiltersConfig)
  const initialFiltersRef = useRef(savedFilters)
  const loadedFiltersRef = useRef(false)
  const loaded = useSelector(state => selectParamsLoaded(state, namespace))

  useEffect(() => {
    if (!loadedFiltersRef.current) {
      const initialFilters = initialFiltersRef.current
      loadedFiltersRef.current = true
      if (Object.keys(initialFilters).length && !Object.keys(getFiltersFromDict(initialSearchParamsRef.current)).length && !initialSearchParamsRef.current.page && !loaded) {
        const { filters: parsedFilters, indexes: parsedIndexes, meta: parsedMeta } = segmentParams(initialFilters)
        console.debug('Updating initial redux query params from default/saved filters', { parsedFilters, parsedIndexes, parsedMeta, initialFilters })
        dispatch(updateQuery({
          queryId: namespace,
          params: {
            indexes: { limit: parseInt(DEFAULT_PAGE_LIMIT), page: 1, search: '', ...parsedIndexes },
            filters: parsedFilters,
            meta: parsedMeta
          },
          abstractOrderVariants: abstractOrderVariants
        }))
      } else if (!loaded) {
        const { filters: parsedFilters, indexes: parsedIndexes, meta: parsedMeta } = segmentParams(initialSearchParamsRef.current)
        console.debug('Updating redux query params from pre-existing filters in url', { parsedFilters, parsedIndexes, parsedMeta })
        dispatch(updateQuery({
          queryId: namespace,
          params: {
            indexes: { limit: parseInt(DEFAULT_PAGE_LIMIT), page: 1, search: '', ...parsedIndexes },
            filters: parsedFilters,
            meta: parsedMeta
          },
          abstractOrderVariants: abstractOrderVariants
        }))
      } else {
        console.debug('Other filters applied or no default filters - skipping redux update.', initialFilters)
      }
      setLoadedFilters(true)
    }
  }, [namespace, loaded, abstractOrderVariants, dispatch])

  const showSaveFilters = useMemo(() => {
    const filters = getFiltersFromDict(searchParams)
    return paramsDictChanged(filters, getFiltersFromDict(savedFilters))
  }, [savedFilters, searchParams])

  const showClearSavedFilters = useMemo(() => {
    return paramsDictChanged(defaultFilters, savedFilters)
  }, [savedFilters, defaultFilters])

  const clearSavedFilters = useCallback(() => {
    console.debug('Clearing saved filters')
    resetSavedFilters()
  }, [resetSavedFilters])

  const saveFilters = useCallback(() => {
    console.debug('Saving filters', searchParams)
    setSavedFilters(getFiltersFromDict(searchParams))
  }, [searchParams, setSavedFilters])

  const currentTableFiltersContext = useMemo(() => {
    console.info('Updating table filters context memo.', { savedFilters, showSaveFilters, showClearSavedFilters })
    return {
      defaultFilters: savedFilters,
      clearSavedFilters: clearSavedFilters,
      saveFilters: saveFilters,
      showSaveFilters: showSaveFilters,
      showClearSavedFilters: showClearSavedFilters
    }
  }, [savedFilters, clearSavedFilters, saveFilters, showSaveFilters, showClearSavedFilters])
  console.debug('Table filters provider updated.', { savedFilters, showSaveFilters, showClearSavedFilters, loadedFilters })

  return (
    <TableFiltersLoadedContext.Provider value={loadedFilters}>
      <TableFiltersContext.Provider value={currentTableFiltersContext}>
        {children}
      </TableFiltersContext.Provider>
    </TableFiltersLoadedContext.Provider>
  )
}

function getNestedToggleVisibilityColumns (columns) {
  const returnColumns = []
  for (const column of columns) {
    if ((column._hideable ?? column.hideable) && !column.collapsedGroup) { // TODO [refactor all tables] remove legacy prop once collapsedGroup is removed
      returnColumns.push(column)
    }
    if (column.columns?.length) {
      returnColumns.push(...getNestedToggleVisibilityColumns(column.columns))
    }
  }
  return returnColumns
}

function preColumnsLoaded (namespace, columns, defaultHidden) {
  console.debug('Pre-parsing loaded columns', { namespace, columns, defaultHidden })
  const parsedColumns = []
  let index = 0
  const parseColumns = (targetList, currentColumns, depth, parentId = null, parentHidden = false, parentCollapsed = 0) => {
    for (const column of (currentColumns ?? [])) {
      const hideable = (column._hideable ?? column.hideable) && !column.collapsedGroup // TODO [refactor all tables] remove legacy prop once collapsedGroup is removed
      const selfHidden = hideable && (parentHidden || defaultHidden.has(column.id))
      const selfCollapsed = column.collapsable && parentId && !column.summaryColumnGroup
      const collapsedValue = selfCollapsed ? parentCollapsed + depth : 0
      const accessor = (column.accessor instanceof Function) ? null : (column.accessor ?? null) // TODO [accessors] select state directly in row cell instead
      const parsedColumn = {
        ...column,
        namespace: namespace,
        __namespacedId: makeId(column.id, namespace),
        columnIds: column.columns?.map(subColumn => subColumn.id) ?? null,
        columns: null,
        parentId: parentId,
        depth: depth,
        index: index,
        ignore: !!column.collapsedGroup,
        defaultCollapsed: selfCollapsed,
        defaultHidden: hideable && defaultHidden.has(column.id),
        hideable: hideable, // TODO [refactor all tables] remove legacy prop once collapsedGroup is removed
        Cell: null,
        Header: null,
        _Header: null, // TODO [accessors] remove once selectable column refactored
        accessor: accessor?.split('.') ?? null, // TODO [accessors] select state directly in row cell
        isSelection: column.id === 'checkbox' // TODO [accessors] consider directly passing this as a config prop instead?
      }
      console.debug('Parsed column', { column, parsedColumn })
      index += 1
      targetList.push(parsedColumn)
      if (column.columns) {
        parsedColumn.columns = []
        parseColumns(parsedColumn.columns, column.columns, depth * 10, column.id, selfHidden, collapsedValue)
      }
    }
  }
  const startDepth = 1
  parseColumns(parsedColumns, columns, startDepth)
  return parsedColumns
}

export function CleanTableColumnsContextProvider ({ columns, defaultHiddenColumns = new Set(), children }) {
  const namespace = useContext(NamespaceContext)
  const dispatch = useDispatch()
  const savedColumnsConfig = {
    key: location.pathname + '-saved-table-hidden-columns-' + (namespace ?? ''),
    defaultValue: defaultHiddenColumns,
    serialize: (value) => JSON.stringify([...value]),
    deserialize: (str) => str === undefined ? new Set(defaultHiddenColumns) : new Set(JSON.parse(str))
  }
  const [savedColumns, setSavedColumns, resetSavedColumns] = useStableLocalStorage(savedColumnsConfig)
  const hiddenColumns = useSelector(state => selectHiddenColumnMenuColumnIdsByNamespace(state, namespace))
  const hiddenColumnsWhenReset = useMemo(() => [...savedColumns], [savedColumns])
  const columnsLoadedRef = useRef(false)
  const [originalColumnData] = useState(() => preColumnsLoaded(namespace, columns, savedColumns))

  useEffect(() => {
    const isDefaultUpdate = !!columnsLoadedRef.current
    console.debug('Dispatching load columns', { namespace, savedColumns, isDefaultUpdate })
    columnsLoadedRef.current = true
    dispatch(columnsLoaded({ namespace: namespace, columns: originalColumnData, hiddenIds: [...savedColumns.values()], isDefaultUpdate: isDefaultUpdate }))
  }, [savedColumns, originalColumnData, namespace, dispatch])

  const toggleableVisibilityColumns = useMemo(() => {
    return getNestedToggleVisibilityColumns(columns)
  }, [columns])

  const updateHiddenColumns = useCallback((columnId, visible) => {
    console.error('Would update hidden column state - should not happen!', { columnId, visible })
    if (visible === null && columnId === null) { // Reset
      console.error('Would set hidden columns', { savedColumns })
    } else if (columnId === null) { // Show/Hide All
      console.error('Would set hidden columns', { fromReset: visible ? [] : toggleableVisibilityColumns.map(column => column.id) })
    } else {
      console.error('Would set column hide state', { visible, columnId })
    }
  }, [toggleableVisibilityColumns, savedColumns])

  const showSaveColumns = useMemo(() => {
    console.debug('Show save columns updating', { savedColumns, hiddenColumns })
    return !areSetsEqual(new Set(hiddenColumns), savedColumns)
  }, [hiddenColumns, savedColumns])

  const showClearSavedColumns = useMemo(() => {
    return !areSetsEqual(defaultHiddenColumns, savedColumns)
  }, [savedColumns, defaultHiddenColumns])

  const clearSavedColumns = useCallback(() => {
    console.debug('Clearing saved hidden columns')
    resetSavedColumns()
  }, [resetSavedColumns])

  const saveColumns = useCallback(() => {
    console.debug('Saving hidden columns')
    setSavedColumns(new Set(hiddenColumns))
  }, [hiddenColumns, setSavedColumns])

  const currentTableColumnsContext = useMemo(() => { // TODO [long term] remove non-save-related actions
    console.info('Updating table columns context memo.', { columns, hiddenColumns, hiddenColumnsWhenReset })
    return {
      columns: columns,
      toggleableVisibilityColumns: toggleableVisibilityColumns,
      defaultHiddenColumns: hiddenColumnsWhenReset,
      hiddenColumnsSet: new Set(hiddenColumns), // TODO [long term] remove
      onColumnVisibilityChange: updateHiddenColumns,
      saveColumns: saveColumns,
      clearSavedColumns: clearSavedColumns,
      showSaveColumns: showSaveColumns,
      showClearSavedColumns: showClearSavedColumns
    }
  }, [columns, toggleableVisibilityColumns, hiddenColumnsWhenReset, hiddenColumns, updateHiddenColumns, saveColumns, clearSavedColumns, showSaveColumns, showClearSavedColumns])
  console.debug('Table columns provider updated.', { columns, hiddenColumns, hiddenColumnsWhenReset })

  return (
    <TableColumnsContext.Provider value={currentTableColumnsContext}>
      {children}
    </TableColumnsContext.Provider>
  )
}

export const TableHiddenColumnSaveControls = memo(function TableHiddenColumnSaveControl () {
  const { saveColumns, clearSavedColumns, showSaveColumns, showClearSavedColumns } = useContext(TableColumnsContext)
  return (
    <Group justify='center'>
      <Tooltip label={showClearSavedColumns ? 'Clear Saved Hidden Columns' : 'No Hidden Columns Saved'}>
        <ActionIcon variant='subtle' aria-label='Clear Saved Hidden Columns' onClick={clearSavedColumns} disabled={!showClearSavedColumns} color='orange'>
          <IconSettingsOff />
        </ActionIcon>
      </Tooltip>
      <Tooltip label={showSaveColumns ? 'Save Hidden Columns' : 'No Changes to Save'}>
        <ActionIcon variant='subtle' aria-label='Save Hidden Columns' onClick={saveColumns} disabled={!showSaveColumns} color='teal'>
          <IconSettingsAutomation />
        </ActionIcon>
      </Tooltip>
    </Group>
  )
})

function createCollapsedColumnMap (columns) {
  const columnMap = new Map()
  const collapseChildren = new Map()
  for (const column of columns) {
    if (column.collapsedGroup) {
      columnMap.set(column.collapsedGroup, column.id)
    }
    if (column.columns?.length) {
      const [recursiveColumnMap, recursiveCollapseChildren] = createCollapsedColumnMap(column.columns)
      for (const [key, value] of recursiveColumnMap.entries()) {
        columnMap.set(key, value)
      }
      if (column.collapsable) {
        const currentCollapseChildren = [...column.columns.map(elem => elem.id)]
        for (const collapseGrandchildren of recursiveCollapseChildren.values()) {
          currentCollapseChildren.push(...collapseGrandchildren)
        }
        collapseChildren.set(column.id, currentCollapseChildren)
      } else {
        for (const [collapseId, collapseGrandchildren] of recursiveCollapseChildren.entries()) {
          collapseChildren.set(collapseId, collapseGrandchildren)
        }
      }
    }
  }
  return [columnMap, collapseChildren]
}

function getFlatDefaultCollapsed (collapseChildren, collapseGroupId, includeCollapseGroup = false) {
  const flatDefaultCollapsed = new Set()
  console.debug('Collapse group id', { collapseGroupId, collapseChildren, includeCollapseGroup })
  for (const collapseChildId of collapseChildren.get(collapseGroupId)) {
    if (collapseChildren.has(collapseChildId)) {
      for (const recursiveCollapseChildId of getFlatDefaultCollapsed(collapseChildren, collapseChildId, includeCollapseGroup)) {
        flatDefaultCollapsed.add(recursiveCollapseChildId)
      }
      if (includeCollapseGroup) {
        flatDefaultCollapsed.add(collapseChildId)
      }
    } else {
      flatDefaultCollapsed.add(collapseChildId)
    }
  }
  return [...flatDefaultCollapsed]
}

export function CleanTableCollapseContextProvider ({ columns, children }) {
  const [[collapseMap, collapseChildren]] = useState(createCollapsedColumnMap(columns))
  const [collapsed, setCollapsed] = useState(new Set([...collapseMap.keys()]))

  const summaryColumnIds = useMemo(() => {
    return [...collapseMap.values()]
  }, [collapseMap])

  const expandedColumnGroupIds = useMemo(() => {
    return [...collapseMap.keys()]
  }, [collapseMap])

  const updateCollapsedColumns = useCallback((collapseGroupId, collapsed) => {
    console.debug('Updating collapsed column state', collapseGroupId, collapsed)
    if (collapsed === null && collapseGroupId === null) { // Reset
      setCollapsed(new Set(expandedColumnGroupIds))
    } else if (collapseGroupId === null) { // Expand/Collapse All
      setCollapsed(new Set(collapsed ? expandedColumnGroupIds : summaryColumnIds))
    } else {
      setCollapsed((prev) => {
        const newCollapsed = new Set(prev)
        if (collapsed) {
          newCollapsed.delete(collapseMap.get(collapseGroupId))
          newCollapsed.add(collapseGroupId)
        } else {
          newCollapsed.delete(collapseGroupId)
          newCollapsed.add(collapseMap.get(collapseGroupId))
        }
        return newCollapsed
      })
    }
  }, [expandedColumnGroupIds, summaryColumnIds, collapseMap])

  const defaultCollapsed = useMemo(() => {
    const flatDefaultCollapsed = new Set()
    for (const defaultCollapseGroupId of expandedColumnGroupIds) {
      for (const collapseChildId of getFlatDefaultCollapsed(collapseChildren, defaultCollapseGroupId)) {
        flatDefaultCollapsed.add(collapseChildId)
      }
    }
    return [...flatDefaultCollapsed]
  }, [expandedColumnGroupIds, collapseChildren])

  const currentTableCollapseContext = useMemo(() => {
    console.info('Updating table collapse context memo.', { collapsed })
    const expandedGroupSummaries = new Set()
    const knownCollapsedChildren = new Set()
    for (const [collapseGroupId, collapseSummaryId] of collapseMap.entries()) {
      if (collapsed.has(collapseGroupId) || knownCollapsedChildren.has(collapseGroupId)) {
        for (const collapseChild of getFlatDefaultCollapsed(collapseChildren, collapseGroupId, true)) {
          knownCollapsedChildren.add(collapseChild)
        }
      } else {
        expandedGroupSummaries.add(collapseSummaryId)
      }
    }
    const expandedSummaries = [...expandedGroupSummaries].filter(elem => !knownCollapsedChildren.has(elem)) // Insert order should prevent this filter from being needed, but that's an implementation detail outside of this function's scope.
    return {
      collapsed: collapsed,
      onCollapseChange: updateCollapsedColumns,
      defaultCollapsed: defaultCollapsed,
      collapseMap: collapseMap,
      collapseChildren: collapseChildren,
      expandedSummaries: expandedSummaries
    }
  }, [collapsed, updateCollapsedColumns, collapseMap, collapseChildren, defaultCollapsed])
  console.debug('Table collapse provider updated.', { columns, collapsed, collapseMap, collapseChildren, defaultCollapsed })

  return (
    <TableCollapseContext.Provider value={currentTableCollapseContext}>
      {children}
    </TableCollapseContext.Provider>
  )
}

/**
 * Redux updates wrapper.
 */
export function CleanTableControlContextProvider ({ children }) {
  const namespace = useContext(NamespaceContext)
  const defaultFiltersLoaded = useContext(TableFiltersLoadedContext)
  const filters = useSelector(state => selectParamsFilters(state, namespace))
  const indexes = useSelector(state => selectParamsIndexes(state, namespace))
  const [, setSearchParams] = useNamespacedStableSearchParams(namespace)

  useEffect(() => {
    if (!defaultFiltersLoaded) {
      console.debug('Skipping search param sync until all filters loaded.', { filters, indexes, defaultFiltersLoaded })
      return
    }
    console.debug('Syncing search params from change in filters or indexes.', { filters, indexes })
    setSearchParams(getNonNullParamsFromDict({ ...indexes, ...(filters ?? {}) }))
  }, [filters, indexes, setSearchParams, defaultFiltersLoaded])

  console.debug('Table control provider updated.', { filters, indexes })

  return (
    <>
      {children}
    </>
  )
}

/**
 * @param actions
 * @param tableActions
 * @param tableActionsLabel
 * @param onRowClick
 * @param noBorders
 * @param searchable
 * @param notDynamic
 * @param filters
 * @param formFilters
 * @param miw
 * @param boxConfig
 * @param bulkActions
 * @param bulkActionsLabel
 * @param columns  TODO [column refactor] remove once columnIds selected in redux
 */
export const CleanTable = memo(function CleanTable (
  {
    columns = [],
    actions = [],
    tableActions = [],
    tableActionsLabel = 'Actions',
    onRowClick = null,
    noBorders = false,
    searchable,
    notDynamic,
    formFilters = [],
    filters = {},
    miw = 500,
    boxConfig = null,
    abstractOrderVariants = null,
    bulkActions = [],
    bulkActionsLabel
  }
) {
  const querying = useContext(TableQueryingContext)

  const boxParams = useMemo(() => {
    const cursorParams = querying ? { style: { cursor: 'progress' } } : {}
    if (boxConfig) {
      if (boxConfig.style && querying) {
        return { miw: miw, ...boxConfig, style: { ...boxConfig.style, cursor: 'progress' } }
      }
      return { miw, ...boxConfig, ...cursorParams }
    }
    return { miw, ...cursorParams }
  }, [miw, boxConfig, querying])

  console.debug('React table updating - columns', { columns })
  return (
    <Box {...boxParams}>
      <SimpleGrid cols={1} verticalSpacing='xs'>
        {!!(formFilters?.length) && (
          <FormFiltersControls formFilters={formFilters} />
        )}
        <TableActionsGroup
          filters={filters}
          bulkActionsLabel={bulkActionsLabel}
          bulkActions={bulkActions}
          tableActionsLabel={tableActionsLabel}
          tableActions={tableActions}
        />
        {!notDynamic && (
          <TopControls
            abstractOrderVariants={abstractOrderVariants}
            searchable={searchable}
            notDynamic={notDynamic}
          />
        )}
        <TopHorizontalScrollbar>
          <QueryBar querying={querying} />
          <FastTable
            columnConfigs={columns}
            abstractOrderVariants={abstractOrderVariants}
            actions={actions}
            onRowClick={onRowClick}
            noBorders={noBorders}
          />
        </TopHorizontalScrollbar>
        <LoadingDataOverlay />
        <RightControls notDynamic={notDynamic} />
      </SimpleGrid>
    </Box>
  )
})

const FastTable = memo(function FastTable ({ columnConfigs = [], abstractOrderVariants = {}, actions = [], onRowClick = false, noBorders = false }) {
  const namespace = useContext(NamespaceContext)
  const hasAnyRows = useSelector(state => selectTableHasAnyRows(state, namespace))
  console.info('FastTable updating', { columnConfigs, hasAnyRows, abstractOrderVariants, actions, onRowClick, noBorders })
  return (
    <Table
      withRowBorders
      withColumnBorders={!noBorders}
      className={styles.table}
      layout='auto'
      w='100%'
      miw='100%'
    >
      <TableHeader columnConfigs={columnConfigs} abstractOrderVariants={abstractOrderVariants} actions={actions} />
      <TableBody columns={columnConfigs} actions={actions} onRowClick={onRowClick} noBorders={noBorders} />
    </Table>
  )
})

const TableHeader = memo(function TableHeader ({ columnConfigs, abstractOrderVariants, actions = [], hidden = false }) {
  console.debug('TableHeader update', { columnConfigs })
  return (
    <Table.Thead>
      <Table.Tr
        h='6rem'
        mah='6rem'
        mih='6rem'
        bg='gray.2'
      >
        {columnConfigs.map(columnConfig => <ColumnHeader key={columnConfig.id} columnId={columnConfig.id} columnConfig={columnConfig} abstractOrderVariants={abstractOrderVariants} parentHidden={hidden} />)}
        {actions.length > 0 && (
          <Table.Th
            miw='1rem'
          />
        )}
      </Table.Tr>
    </Table.Thead>
  )
})

const ColumnHeader = memo(function ColumnHeader ({ columnId, columnConfig, abstractOrderVariants = {}, parentHidden = false, parentCollapsed = false }) {
  const namespace = useContext(NamespaceContext)
  const dispatch = useDispatch()
  const column = useSelector(state => selectTableColumnById(state, makeId(columnId, namespace)))
  const collapsed = !!column?.collapsed
  const selfHidden = !!column?.hidden
  const columnExists = !!column
  const headerPadding = useMemo(() => {
    const PADDING_SIZE = 'lg'

    if (!columnExists || !column.sortable) return {}
    if (column.align === 'center') return { px: PADDING_SIZE }
    return { pr: PADDING_SIZE }
  }, [columnExists, column?.sortable, column?.align])

  // TODO [long term] may as well select order variants from a solo context provider

  if (!column) {
    console.debug('No column returned from state for column header id - skipping', { columnId, column })
    return (
      <>
      </>
    )
  }
  const isIgnoredLegacyCollapseColumn = !!column.collapsedGroup
  const collapseHidden = (!column.summaryColumnGroup && parentCollapsed) || isIgnoredLegacyCollapseColumn
  const isHidden = selfHidden || parentHidden || collapseHidden

  console.debug('ColumnHeader component updating', { column, abstractOrderVariants, parentHidden, parentCollapsed, collapsed, isHidden })
  const subColumnIds = columnConfig.columns
  // const subColumnIds = column.columnIds  // TODO [column refactor] replace access here with .columnIds
  if (subColumnIds) {
    return (
      <>
        {subColumnIds.map(subColumnId => (
          <ColumnHeader
            key={subColumnId.id} // TODO [column refactor] remove .id access once note above resolved
            columnId={subColumnId.id}
            columnConfig={subColumnId} // TODO [column refactor] remove columnConfig prop once note above resolved
            abstractOrderVariants={abstractOrderVariants}
            parentHidden={isHidden}
            parentCollapsed={collapsed}
          />
        ))}
      </>
    )
  }

  const displayParams = isHidden ? { display: 'none' } : {}
  const headerComponent = column.isSelection ? (columnConfig._Header ? null : (<Text>Fix Me</Text>)) : columnConfig.Header // TODO [column refactor] remove after header refactor
  const RenderHeaderComponent = ((columnConfig.id === 'checkbox') && columnConfig._Header) ? columnConfig._Header : null
  return (
    <Table.Th
      w={column.width ?? 'auto'}
      miw={column.miw ?? column.width ?? '6rem'}
      { ...displayParams }
    >
      {(!!column.summaryColumnGroup && !!column.parentId) && (
        <Space h='md'/>
      )}
      <Flex align='center' justify='center' pos='relative' w='100%' {...headerPadding}>
        {!!headerComponent && (headerComponent)}
        {!!RenderHeaderComponent && (<RenderHeaderComponent { ...(columnConfig.newHeaderProps ?? {}) } />)}
        {column.sortable && (
          <Box pos='absolute' right={0}>
            {column.sortingAccessors
              ? (
              <MultiSort
                column={column}
                abstractOrderVariants={abstractOrderVariants}
                parentId={column.parentId ?? column.id}
                updateSortId={column.parentId ? (column.sortId ?? column.id) : (column.sortId ?? null)}
              />
                )
              : (
              <Sort
                column={column}
                abstractOrderVariants={abstractOrderVariants}
                parentId={column.parentId ?? column.id}
                updateSortId={column.parentId ? (column.sortId ?? column.id) : (column.sortId ?? null)}
              />
                )
            }
          </Box>
        )}
      </Flex>
      {(!!column.summaryColumnGroup && !!column.parentId) && (
        <Group justify='center' wrap='nowrap' my={0} py={0}>
          <ActionIcon
            size='xxs'

            onClick={() => dispatch(updateColumnCollapsed({ id: column.parentId, namespace: namespace, collapsed: !parentCollapsed }))}>
            {parentCollapsed ? <IconArrowAutofitRight/> : <IconArrowAutofitLeft/>}
          </ActionIcon>
        </Group>
      )}
    </Table.Th>
  )
})

const Sort = memo(function Sort ({ column, abstractOrderVariants, size = 'xs', parentId = null, updateSortId = null }) {
  const namespace = useContext(NamespaceContext)
  const dispatch = useDispatch()
  const newOrderBy = column.sortId ?? column.id
  const orderDirection = useSelector(state => selectTableColumnOrderBy(state, namespace, newOrderBy))
  const defaultOrderDirection = column.defaultSortOrder ?? 'ASC'
  const nextOrderDirection = (orderDirection === true ? (defaultOrderDirection === 'ASC' ? 'DESC' : 'ASC') : (orderDirection ? (orderDirection === 'ASC' ? 'DESC' : 'ASC') : defaultOrderDirection))

  const updateSort = () => {
    const newOrderDirection = nextOrderDirection ?? column.defaultSortOrder ?? 'ASC'
    console.debug('Updating sort with next order direction', { column, newOrderDirection, newOrderBy, nextOrderDirection, orderDirection, defaultOrderDirection })
    if (column.abstractOrder) {
      dispatch(batchUpdateParams({
        queryId: namespace,
        fieldsToValues: {
          order_by: column.abstractOrder,
          [abstractOrderVariants[column.abstractOrder]]: newOrderBy,
          order_direction: newOrderDirection
        },
        abstractOrderVariants: abstractOrderVariants,
        parentId: parentId,
        updateSortId: updateSortId
      }))
    } else {
      dispatch(batchUpdateParams({
        queryId: namespace,
        fieldsToValues: {
          order_by: newOrderBy,
          order_direction: newOrderDirection
        },
        abstractOrderVariants: abstractOrderVariants,
        parentId: parentId,
        updateSortId: updateSortId
      }))
    }
  }

  console.debug('Sort component updating', { parentId, updateSortId, orderDirection, nextOrderDirection, column, abstractOrderVariants, size })
  return ( // TODO discuss - order direction indicator (asc/desc) is currently flipped for all other react tables.
    <ActionIcon color='gray' variant={!orderDirection && 'subtle'} size={size} onClick={updateSort}>
      {orderDirection
        ? nextOrderDirection === 'ASC'
          ? <IconChevronDown/>
          : <IconChevronUp/>
        : <IconSelector/>
      }
    </ActionIcon>
  )
})

const MultiSort = memo(function MultiSort ({ column, abstractOrderVariants, parentId = null, updateSortId = null }) {
  const namespace = useContext(NamespaceContext)
  const newOrderBy = column.sortId ?? column.id
  const orderDirection = useSelector(state => selectTableColumnOrderBy(state, namespace, newOrderBy))
  const defaultOrderDirection = column.defaultSortOrder ?? 'ASC'
  const nextOrderDirection = (orderDirection === true ? (defaultOrderDirection === 'ASC' ? 'DESC' : 'ASC') : (orderDirection ? (orderDirection === 'ASC' ? 'DESC' : 'ASC') : defaultOrderDirection))
  console.debug('Multi sort component updating', { parentId, updateSortId, orderDirection, nextOrderDirection, column, abstractOrderVariants })
  return (
    <Dropdown
      target={(
        <ActionIcon color='gray' size='xs'>
          {orderDirection
            ? nextOrderDirection === 'ASC'
              ? <IconChevronDown />
              : <IconChevronUp />
            : <IconSelector />
          }
        </ActionIcon>
      )}
      openDelay={150}
      closeDelay={300}
    >
      <Group>
        {column.sortingAccessors.map(sorting => (
          <MultiSortChild
            key={sorting.id}
            column={sorting}
            abstractOrderVariants={abstractOrderVariants}
            parentId={parentId}
            updateSortId={updateSortId ?? newOrderBy}
          />
        ))}
      </Group>
    </Dropdown>
  )
})

const MultiSortChild = memo(function MultiSortChild ({ column, abstractOrderVariants, updateParent }) {
  console.debug('MultiSortChild component updating', { column })
  return (
    <Stack justify='center' align='center'>
      <Text>{column.label}</Text>
      <Sort
        column={column}
        abstractOrderVariants={abstractOrderVariants}
        size='md'
        updateParent={updateParent}
      />
    </Stack>
  )
})

const TableBody = memo(function TableBody ({ columns = [], actions = [], onRowClick = false, noBorders = false }) {
  const columnMap = useMemo(() => {
    const componentMap = new Map()
    const mapColumns = (currentColumns) => {
      for (const column of currentColumns) {
        componentMap.set(column.id, column.Cell)
        if (column.columns) {
          mapColumns(column.columns)
        }
      }
    }

    mapColumns(columns)
    return componentMap
  }, [columns])

  console.debug('TableBody updating', { columnMap, columns, actions, onRowClick, noBorders })
  return (
    <Table.Tbody>
      <TableRows
        columnMap={columnMap}
        actions={actions}
        onRowClick={onRowClick}
        noBorders={noBorders}
      />
      <NoDataRow />
    </Table.Tbody>
  )
})

const rowSize = 64
const nearScreenBuffer = rowSize * 10

const TableRows = memo(function TableRows (
  {
    columnMap,
    actions = [],
    onRowClick = false,
    noBorders = false
  }
) {
  const namespace = useContext(NamespaceContext)
  const rowIds = useSelector(state => selectTableRowIdsByActiveRequestId(state, namespace))
  console.info('TableRows updating', { columnMap, rowIds, actions, onRowClick, noBorders })
  return (
    <>
      {rowIds.map((rowId, index) => {
        return (
          <TableRow
            key={rowId}
            rowId={rowId}
            columnMap={columnMap}
            actions={actions}
            onRowClick={onRowClick}
            noBorders={noBorders}
            alwaysVisible={index < 6}
          />
        )
      })}
    </>
  )
})

const TableRow = memo(function TableRow ({ rowId, columnMap, actions = [], onRowClick = false, noBorders = false, alwaysVisible = false }) {
  const namespace = useContext(NamespaceContext)
  const dispatch = useDispatch()
  const RowWrapper = useContext(TableRowContext)
  const onClick = !onRowClick
    ? null
    : () => {
        if (window.getSelection().toString().length === 0) {
          onRowClick(rowId) // TODO [on click] refactor
          console.warn(
            'Dispatching on row click event - please refactor this into a generic redux dispatch so that it cannot ever cause a full rerender',
            { rowId, onRowClick, dispatch, namespace }
          ) // TODO [on click] update selected row in redux with dispatch instead of passing callback - pass namespace as second prop
        }
      }

  const visible = useSelector(state => selectRowActive(state, namespace, rowId)) || alwaysVisible

  console.debug('TableRow row updating', { rowId, visible, alwaysVisible, columnMap, actions, onRowClick, noBorders, namespace })
  return (
    <RowWrapper
      onClick={onClick}
      data-rowid={`${rowId}`}
      rowId={rowId}
      className={styles.tableRow}
    >
      {!!visible && (
        <VirtualTableRow
          rowId={rowId}
          columnMap={columnMap}
          actions={actions}
          onRowClick={onRowClick}
          noBorders={noBorders}
        />
      )}
      {!visible && (
        <ViewportCanary rowId={rowId} namespace={namespace} dispatch={dispatch} colSpan={columnMap.size} />
      )}
    </RowWrapper>
  )
})

export function DefaultRowWrapper ({ children }) {
  return (
    <Table.Tr
      h='4rem'
      mah='4rem'
      mih='4rem'
      >
      {children}
    </Table.Tr>
  )
}

const defaultUseNearScreenOptions = { root: null, rootMargin: `${nearScreenBuffer}px ${nearScreenBuffer}px ${nearScreenBuffer}px ${nearScreenBuffer}px`, threshold: 0 }

function useNearScreen (dispatch, onVisibleId = null, onVisibleNamespace = null, options = defaultUseNearScreenOptions) {
  const containerRef = useRef(null)

  useEffect(() => {
    const callbackFunction = (entries) => {
      console.debug('Use near screen callback', { onVisibleId, onVisibleNamespace, options, entries })
      const [entry] = entries
      if (entry.isIntersecting) {
        console.debug('Starting on visible transition', { onVisibleId, onVisibleNamespace, entry, entries })
        startTransition(() => {
          dispatch(showRow({ namespace: onVisibleNamespace, id: onVisibleId }))
        })
      }
    }
    const observer = new IntersectionObserver(callbackFunction, options)
    const container = containerRef.current
    console.debug('Use new screen effect updating', { options, container })
    if (container) {
      observer.observe(container)

      return () => {
        console.debug('Use new screen effect unmounting', { options, container })
        observer.unobserve(container)
      }
    }
  }, [containerRef, options, dispatch, onVisibleId, onVisibleNamespace])

  return containerRef
}

function ViewportCanary ({ rowId, namespace, dispatch, colSpan }) {
  const ref = useNearScreen(dispatch, rowId, namespace)

  console.debug('ViewportCanary updating', {
    rowId,
    namespace,
    ref,
    colSpan
  })

  return (
    <Table.Td ref={ref} colSpan={colSpan}><MoreDataOverlayContent /></Table.Td>
  )
}

// const MoreDataRow = memo(function MoreDataRow ({ hasMoreRows = true }) { // TODO [long term] use for endless scrolling or remove
//   console.debug('MoreDataRow updating', { hasMoreRows })
//   return (
//     <>
//       {hasMoreRows ? <Table.Tr><MoreDataRowContent /></Table.Tr> : null}
//     </>
//   )
// })

// function MoreDataRowContent () {
//   const namespace = useContext(NamespaceContext)
//   const visibleColumns = useSelector(state => selectActiveColumnIdsByNamespace(state, namespace))
//   const colSpan = visibleColumns.length + 1
//   return (
//     <Table.Td colSpan={colSpan}>
//       <LoadingDataOverlayContent />
//     </Table.Td>
//   )
// }

const VirtualTableRow = memo(function VirtualTableRow ({ rowId, columnMap, actions = [], onRowClick = false, noBorders = false }) {
  console.debug('VirtualTableRow updating', { rowId, columnMap, actions, onRowClick, noBorders })
  return (
    <>
      <RowCells rowId={rowId} columnMap={columnMap} />
      {actions.length > 0 && <RowActions rowId={rowId} actions={actions} noBorders={noBorders} />}
    </>
  )
})

const RowCells = memo(function RowCells ({ rowId, columnMap }) {
  const namespace = useContext(NamespaceContext)
  // const columnIds = ['id', 'name', 12, 13]  // TODO [long term] select column ids from redux after refactoring columnMap
  // const columnIds = [...columnMap.keys()]
  const columnIds = useSelector(state => selectTableColumnIdsByNamespace(state, namespace))
  console.debug('Row cells updating', { rowId, columnIds, columnMap })
  return (
    <>
      {columnIds.map(columnId => (
        <RowSingleCell key={columnId} rowId={rowId} columnId={columnId} columnMap={columnMap} />
      ))}
    </>
  )
})

const RowSingleCell = memo(function RowSingleCell ({ rowId, columnId, columnMap }) {
  const namespace = useContext(NamespaceContext)
  const columnReduxKey = makeId(columnId, namespace)
  const columnVisible = useSelector(state => selectTableColumnActive(state, columnReduxKey))
  const columnAccessor = useSelector(state => selectTableColumnAccessor(state, columnReduxKey)) // TODO [accessors] select state directly in row cell
  const displayProps = columnVisible ? {} : { display: 'none' }
  const ColumnCell = columnMap.get(columnId) ?? MissingRowCellComponent // TODO [accessors] refactor columnMap into an enum indicating which component?
  const row = useSelector(state => selectTableRowById(state, makeId(rowId, namespace))) // TODO [accessors] select state directly in row cell
  console.debug('Row single cell updating', { rowId, columnId, namespace, row, columnMap, columnVisible, ColumnCell })

  const cell = useMemo(() => { // TODO [accessors] select state directly in row cell
    return {
      value: columnAccessor
        ? columnAccessor.reduce((previousResult, currentSegment) =>
          previousResult?.[currentSegment] ?? null,
        row
        )
        : row,
      row: {
        original: row
      }
    }
  }, [row, columnAccessor])

  return (
    <Table.Td { ...displayProps }>
      <ColumnCell
        rowId={rowId}
        columnId={columnId}
        namespace={namespace}
        visible={columnVisible}
        cell={cell} // TODO [accessors] refactor out cell prop?
      />
    </Table.Td>
  )
})

function MissingRowCellComponent ({ rowId, columnId, namespace, visible = true, cell = null }) { // TODO [accessors] phase out cell prop once component defined via string enum
  const row = useSelector(state => selectTableRowById(state, makeId(rowId, namespace)))
  console.debug('Missing row cell component for column', { rowId, columnId, namespace, row, visible, cell })
  return (
    <Text ta='left'>Component Missing</Text>
  )
}

const RowActions = memo(function RowActions ({ rowId, actions = [], noBorders = false }) {
  const namespace = useContext(NamespaceContext)
  const row = useSelector(state => selectTableRowById(state, makeId(rowId, namespace)))
  console.debug('Row actions updating', { rowId, namespace, row, actions, noBorders })
  return (
    <Table.Td>
      <ClickContainer align={noBorders ? 'flex-end' : 'center'}>
        <NewDropdown
          target={(
            <ActionIcon color='gray' size='lg'>
              <IconDotsVertical />
            </ActionIcon>
          )}
          openDelay={300}
          closeDelay={150}
        >
          <DropdownListContent items={actions} itemProps={{ row }} keyPrefix={rowId.toString()} />
        </NewDropdown>
      </ClickContainer>
    </Table.Td>
  )
})

const NoDataRow = memo(function NoDataRow () {
  const namespace = useContext(NamespaceContext)
  const querying = useContext(TableQueryingContext)
  const hasAnyRows = useSelector(state => selectTableHasAnyRows(state, namespace))
  console.debug('No data row updating', { namespace, querying, hasAnyRows })
  return (
    <>
      {(!hasAnyRows && !querying) ? <NoDataRowContent /> : null}
    </>
  )
})

function NoDataRowContent () {
  const namespace = useContext(NamespaceContext)
  const visibleColumns = useSelector(state => selectActiveColumnIdsByNamespace(state, namespace))
  const colSpan = visibleColumns.length + 1
  return (
    <>
      <Table.Tr><Table.Td colSpan={colSpan}><Text>No rows found</Text></Table.Td></Table.Tr>
    </>
  )
}

const LoadingDataOverlay = memo(function LoadingDataOverlay () {
  const namespace = useContext(NamespaceContext)
  const querying = useContext(TableQueryingContext)
  const hasAnyRows = useSelector(state => selectTableHasAnyRows(state, namespace))
  console.debug('Loading data overlay updating', { namespace, querying, hasAnyRows })
  return (
    <>
      {(!hasAnyRows && !!querying) ? <LoadingDataOverlayContent /> : null}
    </>
  )
})

const loadingOverlayProps = { blur: 4, backgroundOpacity: 0.6 }

function LoadingDataOverlayContent () {
  return (
    <Box
      pos='relative'
      mih='4rem'
      w='100%'
      h='100%'
      bg='gray.2'
    >
      <LoadingOverlay
        zIndex={200}
        visible={true}
        overlayProps={loadingOverlayProps}
      />
    </Box>
  )
}

function MoreDataOverlayContent () {
  return (
    <Box
      pos='relative'
      mih='4rem'
      w='100%'
      h='100%'
      bg='gray.2'
    />
  )
}

const FormFiltersControls = memo(function FormFiltersControls ({ formFilters }) {
  const namespace = useContext(NamespaceContext)
  const dispatch = useDispatch()

  const onSubmit = useCallback((fieldsToValues) => {
    dispatch(batchUpdateParams({ queryId: namespace, fieldsToValues: fieldsToValues }))
  }, [dispatch, namespace])

  console.debug('FormFiltersControls updating', { formFilters, namespace })
  return (
    <FormFilters
      namespace={namespace}
      formFilters={formFilters}
      onSubmit={onSubmit}
    />
  )
})

const TableActionsGroup = memo(function TableActionsGroup ({ filters, bulkActionsLabel, bulkActions = [], tableActionsLabel = 'Actions', tableActions = [] }) {
  console.debug('TableActionsGroup updating', { bulkActions, bulkActionsLabel, tableActions, tableActionsLabel })

  return (
    <Group position='apart'>
      <FilterControlFrame filters={filters} />
      {!!tableActions.length && (
        <Dropdown
          target={(
            <Button
              leftSection={<IconSettings />}
              variant='filled'>
              {tableActionsLabel}
            </Button>
          )}
          items={tableActions}
        />
      )}
      {!!bulkActions.length && (
        <Dropdown
          target={(
            <Button
              leftSection={<IconBoxMultiple />}
              variant='filled'
            >
              {bulkActionsLabel}
            </Button>
          )}
          items={bulkActions}
        />
      )}
    </Group>
  )
})

const noActiveFiltersFallback = {}

const FilterControlFrame = memo(function FilterControlFrame ({ filters }) {
  const namespace = useContext(NamespaceContext)
  const dispatch = useDispatch()
  const activeFilters = useSelector(state => selectParamsFilters(state, namespace))

  const onChange = useCallback((fieldsToValues) => {
    dispatch(batchUpdateParams({ queryId: namespace, fieldsToValues: fieldsToValues }))
  }, [dispatch, namespace])

  console.debug('FilterControlFrame updating', { filters, activeFilters, namespace })
  return (
    <>
      {!!filters.length && (
        <FilterControl filters={filters} selected={activeFilters ?? noActiveFiltersFallback} onChange={onChange} />
      )}
    </>
  )
})

const TopControls = memo(function TopControls ({ abstractOrderVariants, searchable = true, notDynamic = false }) {
  console.debug('TopControls updating', { abstractOrderVariants, searchable, notDynamic })
  return (
    <Group justify='space-between'>
      <Group spacing='xs'>
        {!!searchable && <SearchBarControls />}
        <TableResetButton abstractOrderVariants={abstractOrderVariants} />
        <HiddenColumnControls />
      </Group>
      <RightControls notDynamic={notDynamic} />
    </Group>
  )
})

const TableResetButton = memo(function TableResetButton ({ abstractOrderVariants = null }) {
  const namespace = useContext(NamespaceContext)
  const dispatch = useDispatch()
  const formFiltersAction = createFormActions('react-table-form-filters')
  const activeFilters = useSelector(state => selectParamsFilters(state, namespace))
  const { defaultFilters } = useContext(TableFiltersContext)

  const resetSort = () => {
    console.info(
      'Resetting hidden columns from reset sort - setting to default hidden and collapsed columns',
      { namespace, abstractOrderVariants }
    )
    dispatch(batchUpdateParams({
      queryId: namespace,
      fieldsToValues: {
        ...Object.fromEntries(Object.entries(activeFilters ?? {}).map(([key, value], index) => [key, undefined])),
        order_by: undefined,
        order_direction: undefined,
        page: 1,
        limit: parseInt(DEFAULT_PAGE_LIMIT),
        search: '',
        ...(abstractOrderVariants ? Object.fromEntries(Object.entries(abstractOrderVariants).map(([orderByFieldValue, abstractOrderByField]) => [abstractOrderByField, undefined])) : {}),
        ...defaultFilters
      },
      abstractOrderVariants: abstractOrderVariants
    }))
    dispatch(resetColumns({ namespace }))
    formFiltersAction.reset()
  }

  console.debug('TableResetButton updating', { abstractOrderVariants, activeFilters, defaultFilters, namespace })
  return (
    <Tooltip label='Reset table'>
      <ActionIcon
        onClick={() => resetSort()}
        variant='subtle'
        color='gray'
      >
        <IconRefresh />
      </ActionIcon>
    </Tooltip>
  )
})

const HiddenColumnControls = memo(function HiddenColumnControls () {
  const namespace = useContext(NamespaceContext)
  const toggleableVisibilityColumns = useSelector(state => selectToggleColumnMenuColumnIdsByNamespace(state, namespace))
  // const { toggleableVisibilityColumns } = useContext(TableColumnsContext)

  console.debug('HiddenColumnControls updating', { toggleableVisibilityColumns })
  return (
    <>
      {!!toggleableVisibilityColumns.length && (
        <HiddenColumnsDropdown />
      )}
    </>
  )
})

const HiddenColumnsDropdown = memo(function HiddenColumnsDropdown () { // TODO [legacy dropdown usage] dropdown issues
  const namespace = useContext(NamespaceContext)
  const dispatch = useDispatch()
  // TODO [legacy dropdown usage] refactor dropdown into selecting components, then just pass ids
  const toggleableVisibilityColumns = useSelector(state => selectToggleColumnMenuColumnsByNamespace(state, namespace))
  const hiddenColumns = useSelector(state => selectHiddenColumnIdsByNamespace(state, namespace))
  const dropdownItems = useMemo(() => { // TODO [legacy dropdown usage] refactor these into components
    console.debug('calculating toggle visible columns dropdown items memo', { toggleableVisibilityColumns })
    return toggleableVisibilityColumns.map(column => {
      const targetColumnVisible = !column.hidden
      return {
        label: column.headerLabel ?? column.Header.props?.children ?? 'N/A',
        c: targetColumnVisible ? 'var(--mantine-color-text)' : 'dimmed',
        onClick: () => {
          console.debug('Called on click for column visibility', column.id, column)
          dispatch(updateColumnVisibility({ namespace: namespace, id: column.id, hidden: !!targetColumnVisible }))
        },
        leftSection: targetColumnVisible ? <IconEye /> : <IconEyeOff />
      }
    })
  }, [toggleableVisibilityColumns, namespace, dispatch])

  console.debug('HiddenColumnsDropdown updating', { toggleableVisibilityColumns })
  return (
    <NewDropdown
      target={(
        <ActionIcon color='gray' title='Show/Hide columns'>
          <IconEye />
        </ActionIcon>
      )}
    >
      <DropdownListContent items={dropdownItems} extrasAlwaysOpen>
        <CollapsibleContent hiddenColumns={hiddenColumns} dispatch={dispatch} namespace={namespace} />
      </DropdownListContent>
    </NewDropdown>
  )
})

const CollapsibleContent = memo(function CollapsibleContent ({ hiddenColumns, dispatch, namespace }) {
  return (
    <>
      <Flex justify='center' mb='sm'>
        <Button
          disabled={!hiddenColumns.length}
          onClick={() => {
            console.info('Resetting table hidden columns - showing all and setting default collapsed')
            dispatch(showAllColumnsAndResetExpansion(namespace))
          }}
          variant='light'
          size='compact-sm'
          >
          Show All
        </Button>
      </Flex>
      <TableHiddenColumnSaveControls />
    </>
  )
})

const SearchBarControls = memo(function SearchBarControls () {
  const namespace = useContext(NamespaceContext)
  const dispatch = useDispatch()
  console.debug('Search bar controls updating', { namespace })
  return (
    <SearchBarData
      namespace={namespace}
      onChange={(value) => dispatch(updateParamField({ queryId: namespace, field: 'search', value: value }))}
    />
  )
})

function SearchBarData ({ namespace, onChange }) {
  const search = useSelector(state => selectParamsSearch(state, namespace)) ?? ''
  console.debug('Search bar data updating', { namespace, search })
  return (
    <SearchBar value={search} onChange={onChange} />
  )
}

const RightControls = memo(function RightControls ({ notDynamic = false }) {
  const namespace = useContext(NamespaceContext)
  const pagePotentiallyString = useSelector(state => selectParamsPage(state, namespace)) ?? 1
  const limitPotentiallyString = useSelector(state => selectParamsLimit(state, namespace)) ?? DEFAULT_PAGE_LIMIT
  const total = useSelector(state => selectTableTotal(state, namespace))
  const limit = (typeof limitPotentiallyString === 'string' || limitPotentiallyString instanceof String) ? parseInt(limitPotentiallyString) : limitPotentiallyString
  const page = (typeof pagePotentiallyString === 'string' || pagePotentiallyString instanceof String) ? parseInt(pagePotentiallyString) : pagePotentiallyString

  console.debug('RightControls updating', { page, limit, total, notDynamic, namespace })
  return (
    <Flex justify='flex-end' align='center' gap='xs' style={{ zIndex: 0 }}>
      {total > 0 && (
        <Badge color='blue' variant='outline' size='md'>
          Showing {Math.max(page * limit - (limit - 1), total ? 1 : 0)}-{limit ? Math.min(limit * page, total) : total} of {total}
        </Badge>
      )}
      {!notDynamic && <PageControls page={page} limit={limit} total={total} notDynamic={notDynamic} />}
    </Flex>
  )
})

const PageControls = memo(function PageControls ({ page, limit, total, notDynamic = false }) {
  const namespace = useContext(NamespaceContext)
  const dispatch = useDispatch()

  const pageTotal = limit ? Math.ceil(total / limit) : 1
  console.debug('PageControls updating', { notDynamic, namespace })
  return (
    <Flex justify='flex-end' gap='xs'>
      {!notDynamic && pageTotal > 1 && (
        <Paginator
          total={pageTotal}
          page={page}
          onUpdate={(value) => dispatch(updateParamField({ queryId: namespace, field: 'page', value: value }))}
        />
      )}
      <Tooltip label='Number of rows'>
        <Select
          value={limit.toString()}
          onChange={(value) => dispatch(updateParamField({ queryId: namespace, field: 'limit', value: value ? parseInt(value) : null }))}
          data={PAGE_LIMIT_SIZES}
          w={70}
          miw={60}
          size='xs'
        />
      </Tooltip>
    </Flex>
  )
})

const scrollViewportProps = { style: { transform: 'rotateX(180deg)', overflowX: 'auto' } }
const scrollBoxProps = { style: { transform: 'rotateX(180deg)' } }

function TopHorizontalScrollbar ({ children }) {
  return (
    <Box w='100%' {...scrollBoxProps}>
      <ScrollArea w='100%' scrollbars='x' viewportProps={scrollViewportProps}>
        <Box pos='relative'>
          {children}
        </Box>
      </ScrollArea>
    </Box>
  )
}

function QueryBar ({ querying }) {
  const BAR_WIDTH = 30
  return (
    <Box className={queryBarClasses.wrapper}>
      {querying &&
        <Progress.Root classNames={queryBarClasses}>
          <span className={queryBarClasses.sectionWrapper}>
          <Progress.Section striped value={BAR_WIDTH} color='blue' />
          </span>
        </Progress.Root>
      }
    </Box>
  )
}

export function ClickContainer ({ children, ...props }) {
  if (children.length === 0) return null
  return (
    <Flex direction='column' align='center' {...props} onClick={e => e.stopPropagation()}>{children}</Flex>
  )
}

export function Header ({ children, centered, ...props }) {
  return (
    <Flex
      w='100%'
      fw='630'
      fz='md'
      c='gray.7'
      justify={centered ? 'center' : 'flex-start'}
      ta={centered ? 'center' : 'left'}
      {...props}
    >
      {children}
    </Flex>
  )
}

export function NumberCell ({ centered = false, children, wrapperProps = {}, ...props }) {
  const content = useMemo(() => (
    <Flex
      direction='column'
      align='flex-end'
      ta='right'
      {...props}
    >
      {children}
    </Flex>
  ), [children, props])

  if (centered) {
    return (
      <Flex
        justify='center'
        align='center'
        {...wrapperProps}
      >
        {content}
      </Flex>
    )
  }

  return content
}

export function RezviewCell ({ name, applicantId, address = null, isAdmin = false, ...props }) {
  const dispatch = useDispatch()

  const handleClick = useCallback((event) => {
    event.stopPropagation()
    dispatch(openModal({ modal: 'rezview', id: applicantId }))
  }, [dispatch, applicantId])

  return (
    <>
      <Flex
        direction='column'
        align="flex-start"
        justify="space-around"
        {...props}
      >
        <Box onClick={e => e.stopPropagation()}>
        <Button
          size="compact-md"
          variant='transparent'
          onClick={handleClick}
          styles={() => ({
            root: {
              paddingLeft: 0,
              paddingRight: 0,
              paddingBottom: 0
            }
          })}
        >
          {name}
        </Button>
        </Box>
        {address && <Text size='xs' c='dimmed'>{address}</Text>}

      </Flex>
    </>
  )
}
